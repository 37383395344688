// src/components/HotTourWidget/HotTourWidget.js
import React, { useEffect } from "react";

if (typeof crypto.randomUUID !== "function") {
  crypto.randomUUID = function () {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
}

const Gallery = ({ onWidgetLoaded }) => {
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "//tourvisor.ru/module/init.js";
  //   script.async = true;
  //   script.onload = onWidgetLoaded;
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, [onWidgetLoaded]);

  return (
    <div className="gallery-width">
      <div className="section-gallery">
        <div class="tv-image-slider tv-moduleid-9972984"></div>
      </div>
    </div>
  );
};

export default Gallery;
