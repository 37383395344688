import React, { useEffect } from "react";
import BackCallPost from "../BackCallPost/BackCallPost";

import './Banner.css'
 

const Banner = ({ isVisa, reverse, img, text }) => {
    return <div className="banner">
        {!reverse ?
            <> <div className="banner-content">
                <h2>{text}</h2>
                {isVisa ? <BackCallPost buttonText="Оформить Визу" isVisa={true} secondText="Заявка на оформление Визы" /> : <BackCallPost />}
            </div>
                <div className="banner-image">
                    <img src={img} alt="Happy people with visa" />
                </div></>

            : <>
                <div className="banner-image">
                    <img src={img} alt="Happy people with visa" />
                </div>
                <div className="banner-content">
                    <h2>{text}</h2>
                    {isVisa ?  <BackCallPost buttonText="Оформить Визу"  isVisa={true} secondText="Заявка на оформление Визы" /> : <BackCallPost  />}
                </div>
            </>
        }

    </div>;
};

export default Banner;
