// src/components/HotTourWidget/HotTourWidget.js
import React, { useEffect } from "react";
import "./style.css";

if (typeof crypto.randomUUID !== 'function') {
  crypto.randomUUID = function() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  };
}

const BlockSection = ({ onWidgetLoaded }) => {
   

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        // position: "relative",
        alignItems: "center",
        // top: "-20px",
        marginTop: 100  ,
        marginBottn: 100

      }}
    >
      <div style={{ width: "90%" }}>
        {/* <h2 className="hotTitle">
          Хотите отправиться в горящий тур? 🔥
        </h2>
  */}
        <div class="tv-min-price tv-moduleid-9973023"></div>
      </div>
    </div>
  );
};

export default BlockSection;
