
import React from 'react';
import { GoogleMap, useLoadScript, InfoWindowF } from '@react-google-maps/api';
import './CompanyLocationsMap.css';

const googleMapsApiKey = "AIzaSyCIkOMl1qfgNHCfXUqXThxDi4YLxFSJ47o";
const officeData = {
    minsk: [
        {
            city: 'Минск',
            address: 'ул.Кульман, 3, 1 этаж, офис 112 ТЦ "Coolman"',
            position: { lat: 53.9156, lng: 27.5809 }
        },
        {
            city: 'Минск',
            address: 'ул.Берута, 3б, 3 этаж, офис 306',
            position: { lat: 53.9345, lng: 27.6214 }
        },
    ],
    brest: [
        {
            city: 'Брест',
            address: 'ул.Советская 73, 3 этаж, офис 305 (вход с торца)',
            position: { lat: 52.0938, lng: 23.6854 }
        },
    ],
    grodno: [
        {
            city: 'Гродно',
            address: 'ул.Советская 18, 1 этаж, офис 120 (ТЦ "Неман")',
            position: { lat: 53.6833, lng: 23.8342 }
        },
        {
            city: 'Гродно',
            address: 'ул. Советская 2а, 2 этаж, офис 211',
            position: { lat: 53.6842, lng: 23.8478 }
        },
    ],
    mogilev: [
        {
            city: 'Могилёв',
            address: 'ул.Первомайская 19, 1 этаж (вход с улицы)',
            position: { lat: 53.8945, lng: 30.3304 }
        },
    ],
};

const containerStyle = {
    width: '100%',
    height: '300px',
    marginBottom: '20px',
};

const infoWindowStyle = {
    padding: '2px',
    maxWidth: '100px',
    fontSize: '10px',
    lineHeight: '1',
};

const CompanyLocationsMap = () => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: googleMapsApiKey,
    });

    if (loadError) return <div>Ошибка загрузки карты</div>;
    if (!isLoaded) return <div>Загрузка...</div>;

    return (
        <div className="map-container">
            <h3>Офисы Минска</h3>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={officeData.minsk[0].position}
                zoom={14}
            >
                {officeData.minsk.map((office, index) => (
                    <InfoWindowF
                        key={index}
                        position={office.position}
                        options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
                    >
                        <div className='infoWindowStyle' style={infoWindowStyle}>
                            <h4>{office.city}</h4>
                            <p>{office.address}</p>
                        </div>
                    </InfoWindowF>
                ))}
            </GoogleMap>

            <h3>Офис Брест</h3>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={officeData.brest[0].position}
                zoom={14}
            >
                <InfoWindowF
                    position={officeData.brest[0].position}
                    options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
                >
                  <div className='infoWindowStyle' style={infoWindowStyle}>
                        <h4>{officeData.brest[0].city}</h4>
                        <p>{officeData.brest[0].address}</p>
                    </div>
                </InfoWindowF>
            </GoogleMap>

            <h3>Офисы Гродно</h3>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={officeData.grodno[0].position}
                zoom={14}
            >
                {officeData.grodno.map((office, index) => (
                    <InfoWindowF
                        key={index}
                        position={office.position}
                        options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
                    >
                        <div className='infoWindowStyle' style={infoWindowStyle}>                            <h4>{office.city}</h4>
                            <p>{office.address}</p>
                        </div>
                    </InfoWindowF>
                ))}
            </GoogleMap>

            <h3>Офис Могилёв</h3>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={officeData.mogilev[0].position}
                zoom={14}
            >
                <InfoWindowF
                    position={officeData.mogilev[0].position}
                    options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
                >
                    <div className='infoWindowStyle' style={infoWindowStyle}>
                        <h4>{officeData.mogilev[0].city}</h4>
                        <p>{officeData.mogilev[0].address}</p>
                    </div>
                </InfoWindowF>
            </GoogleMap>
        </div>
    );
};

export default CompanyLocationsMap;