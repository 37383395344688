import React, { useState } from 'react';
import Modal from 'react-modal';
import p1 from '../../assets/images/contacts/p1.jpg'
import p2 from '../../assets/images/contacts/p2.jpg'
import p3 from '../../assets/images/contacts/p3.jpg'
import p4 from '../../assets/images/contacts/p4.jpg'
import p5 from '../../assets/images/contacts/p5.png'
import p6 from '../../assets/images/contacts/p6.png'
import p7 from '../../assets/images/contacts/p7.png'
import p8 from '../../assets/images/contacts/p8.jpg'
import p9 from '../../assets/images/contacts/p9.jpg'
import p10 from '../../assets/images/contacts/p10.jpg'
import p11 from '../../assets/images/contacts/p11.jpg'
import p12 from '../../assets/images/contacts/p12.jpg'

import './gallery-c.css'
const customModalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '80%',
        maxHeight: '80%',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
    },
};

const images = [
    {
        src: p1,
        title: 'Офис г. Минск ул.Берута 3б, 3 этаж,офис 305 (БЦ Пушкинский ) ',
        description: 'Description for image 1',
        price: '$100'
    },
    {
        src: p2,
        title: 'Офис г.Брест ул.Советская 73, 3 этаж, офис 305',
        description: 'Description for image 2',
        price: '$200'
    },
    {
        src: p3,
        title: 'Офис  ТЦ "Coolman", ул.Кульман, 3',
        description: 'Description for image 3',
        price: '$300'
    },
    {
        src: p4,
        title: 'Офис г.Могилев, ул.Первомайская 19, 1 этаж (вход с улицы)',
        description: 'Description for image 4',
        price: '$400'
    },
    {
        src: p5,
        title: 'Офис г.Могилев, ул.Первомайская 19, 1 этаж (вход с улицы)',
        description: 'Description for image 4',
        price: '$400'
    },  {
        src: p6,
        title: 'Офис г.Могилев, ул.Первомайская 19, 1 этаж (вход с улицы)',
        description: 'Description for image 4',
        price: '$400'
    },  {
        src: p7,
        title: 'Офис г.Могилев, ул.Первомайская 19, 1 этаж (вход с улицы)',
        description: 'Description for image 4',
        price: '$400'
    },  {
        src: p8,
        title: 'Офис г.Могилев, ул.Первомайская 19, 1 этаж (вход с улицы)',
        description: 'Description for image 4',
        price: '$400'
    },  {
        src: p9,
        title: 'Офис г.Могилев, ул.Первомайская 19, 1 этаж (вход с улицы)',
        description: 'Description for image 4',
        price: '$400'
    },  {
        src: p10,
        title: 'Офис г.Могилев, ул.Первомайская 19, 1 этаж (вход с улицы)',
        description: 'Description for image 4',
        price: '$400'
    },  {
        src: p11,
        title: 'Офис г.Могилев, ул.Первомайская 19, 1 этаж (вход с улицы)',
        description: 'Description for image 4',
        price: '$400'
    },  {
        src: p12,
        title: 'Офис г.Могилев, ул.Первомайская 19, 1 этаж (вход с улицы)',
        description: 'Description for image 4',
        price: '$400'
    },
];

const CustomGallery = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const openModal = (image) => {
        setSelectedImage(image);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedImage(null);
    };

    return (
        <div className="custom-gallery-container">
            <div className="custom-gallery">
                {images.map((image, index) => (
                    <div key={index} className={`custom-gallery-item custom-gallery-item-${index + 1}`} onClick={() => openModal(image)}>
                        <img src={image.src} alt={image.title} />
                        {/* <div className="custom-overlay">
                            <h3>{image.title}</h3>
                        </div> */}
                    </div>
                ))}
                {selectedImage && (
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        style={customModalStyles}
                        contentLabel="Image Modal"
                    >
                        <div className="custom-modal-content">
                            <img src={selectedImage.src} alt={selectedImage.title} />
                            {/* <div className="custom-modal-text">
                                <h2>{selectedImage.title}</h2>

                            </div> */}
                        </div>
                    </Modal>
                )}
            </div>
        </div>
    );
};

export default CustomGallery;
