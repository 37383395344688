import React, { useState, useEffect } from "react";
import miceImage from "../../assets/images/tours.png"; // Замените путь на правильный
import conf from "../../assets/images/mice/conf.png";
import exp from "../../assets/images/mice/exp.png";
import meeting from "../../assets/images/mice/meeting.png";
import travel from "../../assets/images/mice/travel.png";
import Gallery from "../../components/Gallery/Gallery";
import TourvisorHotTours from "../../components/TourWidget";
import HotTourWidget from "../../components/HotTourWidget";
import "./tour.css";
import Banner from "../../components/Banner/Banner";
import WhyUs from "../../components/WhyUs/WhyUs";
import ReviewsSection from "../../components/ReviewsSection/ReviewsSection";
import Loader from "../../components/Loader/Loader";
const Tour = () => {
  const [loading, setLoading] = useState(true);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [tourvisorLoaded, setTourvisorLoaded] = useState(false);
  const [hotTourLoaded, setHotTourLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const checkIsMobile = () => setIsMobile(window.innerWidth <= 768);
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);
    return () => window.removeEventListener("resize", checkIsMobile);
  }, []);
   useEffect(() => {
    const handleError = (message, source, lineno, colno, error) => {
      console.error("Caught an error:", message, source, lineno, colno, error);
      return true; // Prevents the default browser error handling
    };

    window.onerror = handleError;

    return () => {
      window.onerror = null; // Cleanup on component unmount
    };
  }, []);

  const handleAllLoaded = () => {
    if (tourvisorLoaded ) {
      setLoading(false);
    }
  };

  return (
    <>
      {" "}
      {loading && <Loader />}
      <div className="tour-tourism">
        {" "}
        {loading && <Loader />}
        <div className={"tour-tourism-contaier"}>
          <div className="tour-intro-section">
            <h1>
              Более 1000 туров на любой бюджет – выберите свой идеальный отдых
            </h1>
            {/* <img src={miceImage} alt="MICE Туризм" className="tour-mice-image" /> */}
            <div className="tours-widget-search">
              <TourvisorHotTours
                onWidgetLoaded={() => {
                  setTourvisorLoaded(true);
                  handleAllLoaded();
                }}
              />
            </div>{" "}
            <Banner
              isVisa={false}
              img={miceImage}
              text="Остались вопросы?"
              reverse={false}
            />
          </div>
          <HotTourWidget
            // onWidgetLoaded={() => {
            //   setHotTourLoaded(true);
            //   handleAllLoaded();
            // }}
          />
        </div>
      </div>
    </>
  );
};

export default Tour;
