// src/App.js
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/Header/Header";
import Main from "./pages/Main/Main";
import AllDestinations from "./pages/AllDestinations/AllDestinations";
import CountryDetails from "./components/CountriesDetails/CountriesDetails";
import Footer from "./components/Footer/Footer";
import Company from "./pages/Company/Company";
import Reviews from "./pages/Reviews/Reviews";
import Transfer from "./pages/Transfer/Transfer";
import MiceTourism from "./pages/MiceTourism /MiceTourism";
import ContactsPage from "./pages/ContactsPage/ContactsPage";
import Callback from "./components/CallBackWidget/Callback";
import Vise from "./pages/Visa/Visa";
import Tour from "./pages/Tour/Tour";
import ErrorBoundary from "./error"; // Импортируем компонент
import BackCallPost from "./components/BackCallPost/BackCallPost";

function App() {
  const [open, setOpen] = useState(false);
  return (
    <Router>
      <ErrorBoundary>
        <div className="App">
          <Header />
          <div className="content">
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/tours" element={<Tour />} />
              <Route path="/o-kompanii/about-us" element={<Company />} />
              <Route path="/o-kompanii/otzyvy" element={<Reviews />} />
              <Route path="/strany" element={<AllDestinations />} />
              <Route
                path="/countries/:countryId"
                element={<CountryDetails />}
              />
              <Route path="/transfer" element={<Transfer />} />
              <Route path="/kontakty" element={<ContactsPage />} />
              <Route path="/visa" element={<Vise />} />
              <Route path="/uslugi/mice" element={<MiceTourism />} />
            </Routes>
          </div>
          <Callback/>
          <Footer />
        </div>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
