import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Header.css";
import logo from "../../assets/images/white.png";
import Callback from "../CallBackWidget/Callback";
import BackCallPost from '../BackCallPost/BackCallPost';

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const isMobile = () => {
    return window.innerWidth <= 568;
  };
  const isHomePage = location.pathname === "/";

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleLinkClick = () => {
    closeMenu();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <header
      className={`header ${scrolled || !isHomePage ? "scrolled" : ""} ${
        menuOpen ? "menu-open" : ""
      }`}
    >
      <div className="header-container">
        {/* {isMobile() && scrolled ? (
          <Link to="/" className="logo-link" onClick={handleLinkClick}>
            <img src={logo} alt="Logo" className="logo" />
          </Link>
        ) : (
          <Link to="/" className="logo-link" onClick={handleLinkClick}>
            <img src={logo} alt="Logo" className="logo" />
          </Link>
        )} */}
        {/* scrolled &&  */}
        {isMobile() ? (
          isHomePage ? (
            scrolled ? (
              <Link to="/" className="logo-link" onClick={handleLinkClick}>
                <img src={logo} alt="Logo" className="logo" />
              </Link>
            ) : null
          ) : (
            <Link to="/" className="logo-link" onClick={handleLinkClick}>
              <img src={logo} alt="Logo" className="logo" />
            </Link>
          )
        ) : (
          <Link to="/" className="logo-link" onClick={handleLinkClick}>
            <img src={logo} alt="Logo" className="logo" />
          </Link>
        )}
        <div
          className={`burger ${menuOpen ? "open" : ""} ${
            scrolled || !isHomePage ? "scrolled" : ""
          }`}
          onClick={toggleMenu}
        >
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
        <div
          className={`nav-overlay ${menuOpen ? "active" : ""}`}
          onClick={closeMenu}
        ></div>
        <nav className={`nav ${menuOpen ? "open" : ""}`}>
          <ul className="nav-list">
            {menuOpen && (
              <li className="nav-item">
                <Link to="/" className="logo-link" onClick={handleLinkClick}>
                  <img src={logo} alt="Logo" className="logo" />
                </Link>
              </li>
            )}
            {menuOpen ||
              (!isHomePage && (
                <li className="nav-item">
                  <Link to="/" className="logo-link" onClick={handleLinkClick}>
                    Главная
                  </Link>
                </li>
              ))}
            <li className="nav-item  ">
              <Link to="/tours" onClick={handleLinkClick}>
                Поиск тура
              </Link>
            </li>{" "}
            <li className="nav-item">
              <Link to="/strany" onClick={handleLinkClick}>
                Страны
              </Link>
            </li>{" "}
            <li className="nav-item">
              <Link to="/uslugi/mice" onClick={handleLinkClick}>
                MICE
              </Link>
            </li>
            <li className="nav-item nav-linka">
              <BackCallPost asText={true} />
            </li>
            <li className="nav-item">
              <Link to="/visa" onClick={handleLinkClick}>
                Визы
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/o-kompanii/about-us" onClick={handleLinkClick}>
                Компания
              </Link>
            </li>{" "}
            <li className="nav-item">
              <Link to="/transfer" onClick={handleLinkClick}>
                Трансфер
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/o-kompanii/otzyvy" onClick={handleLinkClick}>
                Отзывы
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/kontakty" onClick={handleLinkClick}>
                Контакты
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
