import React, { useState } from 'react';
import { GoogleMap, InfoWindowF, useLoadScript } from '@react-google-maps/api';
import './offices.css';

// Example icons for mobile operators
import A1Icon from '../../assets/images/iconso/a1.png';
import MtsIcon from '../../assets/images/iconso/mtc.png';
import LifeIcon from '../../assets/images/iconso/life.png';

const googleMapsApiKey = "AIzaSyCIkOMl1qfgNHCfXUqXThxDi4YLxFSJ47o";

const officeData = {
    minsk: {
        tab: 'Минск',
        contacts: [
            { type: 'A1', phone: '+375-(29)-683-50-50' },
            { type: 'мтс', phone: '+375-(33)-683-50-50' },
            { type: 'life', phone: '+375-(25)-683-50-50' }
        ],
        offices: [
            { city: 'Минск №1', address: 'ул.Кульман,3, 1 этаж, офис 112 ТЦ"Coolman"', position: { lat: 53.9156, lng: 27.5809 } },
            { city: 'Минск  №2', address: 'ул.Берута, 3б, 3 этаж, офис 306', position: { lat: 53.9345, lng: 27.6214 } },
        ]
    },
    brest: {
        tab: 'Брест',
        contacts: [
            { type: 'A1', phone: '+375-(44)-727-60-60' },
            { type: 'мтс', phone: '+375-(29)-7005-411' },
            { type: 'life', phone: '+375-(25)-727-60-60' }
        ],
        offices: [
            { city: 'Брест', address: 'ул.Советская 73, 3 этаж, офис 305 (вход с торца)', position: { lat: 52.0938, lng: 23.6854 } },
        ]
    },
    grodno1: {
        tab: 'Гродно',
        contacts: [
            { type: 'A1', phone: '+375-(44)-557-90-90' },
            { type: 'мтс', phone: '+375-(29)-7005-612' }
        ],
        offices: [
            { city: 'Гродно  №1', address: 'ул.Советская 18, 1 этаж, офис 120 (ТЦ "Неман")', position: { lat: 53.6833, lng: 23.8342 } },
            { city: 'Гродно  №2', address: 'ул. Советская 2а, 2 этаж, офис 211', position: { lat: 53.6842, lng: 23.8478 } },
        ]
    },
    mogilev: {
        tab: 'Могилёв',
        contacts: [
            { type: 'A1', phone: '+375-(29)-370-95-95' },
            { type: 'мтс', phone: '+375-(33)-370-95-95' }
        ],
        offices: [
            { city: 'Могилёв', address: 'ул.Первомайская 19, 1 этаж (вход с улицы)', position: { lat: 53.8945, lng: 30.3304 } },
        ]
    },
};

const OfficeTabs = () => {
    const [selectedTab, setSelectedTab] = useState('minsk');
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: googleMapsApiKey, // Replace with your actual API key
    });

    const containerStyle = {
        width: '100%',
        height: '300px',
    };

    const infoWindowStyle = {
        padding: '10px',
        maxWidth: '150px',
    };

    if (loadError) return <div>Ошибка загрузки карты</div>;
    if (!isLoaded) return <div>Загрузка...</div>;

    // Function to get the operator icon
    const getOperatorIcon = (type) => {
        switch (type.toLowerCase()) {
            case 'a1':
                return <img src={A1Icon} className="operator-icon" />;
            case 'мтс':
                return <img src={MtsIcon} className="operator-icon" />;
            case 'life':
                return <img src={LifeIcon} className="operator-icon" />;
            default:
                return null;
        }
    };

    const renderMap = (offices) => (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={offices[0].position}
            zoom={14}
        >
            {offices.map((office, index) => (
                <InfoWindowF
                    key={index}
                    position={office.position}
                    options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
                >
                    <div style={infoWindowStyle}>
                        <h4>{office.city}</h4>
                        <p>{office.address}</p>
                    </div>
                </InfoWindowF>
            ))}
        </GoogleMap>
    );

    return (
        <div>
            <div className="tabs">
                {Object.keys(officeData).map((key) => (
                    <div
                        key={key}
                        className={`tab ${selectedTab === key ? 'active' : ''}`}
                        onClick={() => setSelectedTab(key)}
                    >
                        {officeData[key].tab}
                    </div>
                ))}
            </div>
            <div className="office-details">
                <div className="contacts">
                    <h2>Контактные номера</h2>
                    {officeData[selectedTab].contacts.map((contact, index) => (
                        <p key={index}>
                            <strong>{getOperatorIcon(contact.type)}:</strong> {contact.phone}
                        </p>
                    ))}
                </div>
                {officeData[selectedTab].offices.map((office, index) => (
                    <div key={index} className="office-info">
                        <h2>{office.city}</h2>
                        <p><strong>Адрес:</strong> {office.address}</p>
                        <div className="map-container">
                            {renderMap([office])}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OfficeTabs;
