import React, { useState } from "react";
import Slider from "react-slick";
import Modal from "react-modal";
import "./ReviewsSection.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import avatar1 from "../../assets/images/review/review1.jpg";
import avatar2 from "../../assets/images/review/review2.jpg";
import avatar3 from "../../assets/images/review/review3.jpg";
import avatar4 from "../../assets/images/review/review4.jpg";
import avatar5 from "../../assets/images/review/review5.jpg";
import reviewImage1 from "../../assets/images/reviewsPhoto/p1.jpg";
import reviewImage2 from "../../assets/images/reviewsPhoto/p2.jpg";
import reviewImage3 from "../../assets/images/reviewsPhoto/p3.jpg";
import reviewImage4 from "../../assets/images/reviewsPhoto/p4.jpg";
import reviewImage5 from "../../assets/images/reviewsPhoto/p5.jpg"; // Добавьте свои изображения отзывов // Добавьте свои изображения отзывов
// Добавьте свои изображения отзывов // Добавьте свои изображения отзывов
// Добавьте свои изображения отзывов // Добавьте свои изображения отзывов
// Добавьте свои изображения отзывов // Добавьте свои изображения отзывов
import { Link } from "react-router-dom";
import star from "../../assets/images/review/star.png";

const reviews = [
  {
    name: "Светлана Кущ",
    location: "Группа Vk",
    rating: 5,
    reviewImage: reviewImage1, // Изображение с отдыха

    review:
      "Спасибо большое менеджеру Анне за профессинализм и терпение, грамотность в работе. Вы быстро подобрали и забронировали тур в Египет, Шарм эль Шейх.",
    avatar: avatar2,
    link: "https://vk.com/reviews-98811431",
  },
  {
    name: "Наталья Василёнок",
    location: "Группа Vk",
    rating: 5,
    reviewImage: reviewImage2, // Изображение с отдыха

    avatar: avatar5,
    review:
      "Все что могу сказать СУПЕР УЛЕТНО! Ребята молодцы, в частности Богдан, практически заставил меня улететь в Турцию, в страну, которую я не собиралась вообще...",
    link: "https://vk.com/topic-98811431_32387145",
  },
  {
    name: "Каролина Панасенкова",
    location: "Группа Vk",
    rating: 5,
    review:
      "Хотелось бы поблагодарить компанию MakeTravel за отличный отдых, а в частности менеджера Марию Сипач. Это был мой первый опыт подбора тура через турфирму.",
    avatar: avatar1,
    reviewImage: reviewImage3, // Изображение с отдыха
    link: "https://vk.com/topic-98811431_32387145?offset=40",
  },

  {
    name: "Яна Микуть",
    location: "Группа Vk",
    reviewImage: reviewImage4,
    rating: 5,
    avatar: avatar3,
    review:
      "Спасибо огромнейшее maketravel за отличный отдых😊сегодня вернулись из Индии, Гоа, отель Adamo the bellus.   ",
    link: "https://vk.com/topic-98811431_32387145?offset=60",
  },
  {
    name: "Веся Жуковская",
    location: "Группа Vk",
    reviewImage: reviewImage5,

    rating: 5,
    avatar: avatar4,
    review:
      "Ездили в тур по Европе с компанией MakeTravel, организовали все на высшем уровне!Были вопросы во время путешествия и всегда с нами связывались...",
    link: "https://vk.com/topic-98811431_32387145?offset=140",
  },
];

const ReviewsSection = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage(null);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="reviews-section">
      <div className="container">
        <div className="section-header">
          <div>
            <h2>Что говорят о нас наши клиенты?</h2>
            <p style={{ textAlign: "left" }}>
              Мы имеем более <span className="red-span">5150 отзывов </span> в
              разных социальных сетях
            </p>
          </div>
        </div>
        <Slider {...settings}>
          {reviews.map((review, index) => (
            <div key={index} className="review-card">
              <div className="review-content">
                <Link to={review.link} className="review-header">
                  <div className="review-avatar">
                    <img src={review.avatar} alt={`${review.name}'s avatar`} />
                  </div>
                  <div className="review-header-text">
                    <h3>{review.name}</h3>
                    <p className="location">{review.location}</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: "20px",
                    }}
                  >
                    <img
                      src={star}
                      width="20px"
                      height="20px"
                      alt={`${review.name}'s rating`}
                    />
                    <p className="rating"> {review.rating}</p>
                  </div>
                </Link>
                <div className="review-body">
                  <p className="review">{review.review}</p>
                  {review.reviewImage && (
                    <div
                      className="review-thumbnail"
                      onClick={() => openModal(review.reviewImage)}
                    >
                      <img src={review.reviewImage} alt="Review thumbnail" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <Link to="/o-kompanii/otzyvy">
          <button className="explore-button-rew">Посмотреть все отзывы</button>
        </Link>
      </div>
      {selectedImage && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              maxWidth: "80%",
              maxHeight: "70%",
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.75)",
            },
          }}
          contentLabel="Image Modal"
        >
          <img
            src={selectedImage}
            style={{ width: "100%", height: "auto" }}
            alt="Full size"
          />
        </Modal>
      )}
    </section>
  );
};

export default ReviewsSection;
