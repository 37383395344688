import React, { useState, useRef, useEffect } from "react";
import "./BackCallPost.css";

const BackCallPost = ({
  buttonText = "Подобрать тур",
  secondText = "Заявка на подбор тура",
  isOpen,
  asText = false,
  isVisa = false,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalState, setModalState] = useState("form"); // 'form', 'success', 'error'
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    comment: "",
  });
  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowForm(false);
        setModalState("form");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const commentWithVisaPrefix = isVisa
      ? `[VISA] ${formData.comment}`
      : formData.comment;

    try {
      const response = await fetch(
        "https://maketravel.onrender.com/send-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...formData, comment: commentWithVisaPrefix }),
        }
      );

      const result = await response.json();
      if (response.ok) {
        console.log(result.message);
        setFormData({
          name: "",
          email: "",
          phone: "",
          comment: "",
        });
        setModalState("success");
      } else {
        setModalState("error");
      }
    } catch (error) {
      setModalState("error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderModalContent = () => {
    switch (modalState) {
      case "success":
        return (
          <div className="message-container success">
            <svg
              className="checkmark"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle
                className="checkmark__circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                className="checkmark__check"
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
            <h2>Заявка успешно отправлена</h2>
          </div>
        );
      case "error":
        return (
          <div className="message-container error">
            <svg
              className="crossmark"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle
                className="crossmark__circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                className="crossmark__path"
                fill="none"
                d="M16 16 36 36 M36 16 16 36"
              />
            </svg>
            <h2>Что-то пошло не так</h2>
            <p>Свяжитесь с нами по телефону</p>
            <a href="tel:+375296835050" className="phone-link">
              +375-(29)-683-50-50
            </a>
          </div>
        );
      default:
        return (
          <>
            <h2>{secondText}</h2>
            <form
              className="back-call-post__form"
              onSubmit={handleSubmit}
              autoComplete="off"
              noValidate
            >
              <input
                className="back-call-post__input"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Ваше имя"
                required
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
              />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Электронная почта"
                required
              />
              <div className="phone-input">
                <span className="country-code">+375</span>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  placeholder="(00) 000-00-00"
                  required
                />
              </div>
              <textarea
                name="comment"
                value={formData.comment}
                onChange={handleInputChange}
                placeholder="Комментарий"
              ></textarea>
              <button
                type="submit"
                className="submit-button"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Отправка..." : "Отправить"}
              </button>
            </form>
            <p className="privacy-policy">
              Отправляя запрос, Вы подтверждаете <a href="#">согласие</a> на
              обработку персональных данных.{" "}
              <a href="#">Политика обработки персональных данных</a>
            </p>
          </>
        );
    }
  };

  return (
    <>
      {asText && (
        <a onClick={() => setShowForm(true)} className="text-trigger">
          {buttonText}
        </a>
      )}
      <div className="back-call-post">
        {asText ? null : (
          <button
            onClick={() => setShowForm(true)}
            className="contact-button"
            disabled={isSubmitting}
          >
            {buttonText}
          </button>
        )}

        {showForm && (
          <div className="form-overlay">
            <div className="form-container" ref={modalRef}>
              <button
                className="close-button"
                onClick={() => {
                  setShowForm(false);
                  setModalState("form");
                }}
              >
                &times;
              </button>
              {renderModalContent()}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BackCallPost;
