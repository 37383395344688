import React, { useState } from 'react';
import './TransferInfo.css';
import dollar from '../../assets/images/transfer/dollar.png';

import a1 from '../../assets/images/transfer/a1.png';
import mtc from '../../assets/images/transfer/mtc.png';
import life from '../../assets/images/transfer/life.png';
import transfer from '../../assets/images/transfer.png';

const TransferInfo = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleDropdown = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="transfer-info">
            <div className="contact-info transfer-info-content">
                <h1>
                    Мы предлагаем ежедневные трансферы в аэропорты Москвы: Внуково, Домодедово и Шереметьево
                </h1>
                <img src={transfer} alt="Трансфер" className="mice-image" />

                <div className="services-section">
                    <h2>Круглосуточная поддержка по трансферу:</h2>

                    <ul>
                        <li className="number-operator">
                            <img src={a1} width={30} alt="A1" />
                            <a href="tel:+375296835050" style={{ color: 'black' }}>
                                +375 (29) 683-50-50
                            </a>
                        </li>
                        <li className="number-operator">
                            <img src={mtc} width={30} alt="MTC" />
                            <a href="tel:+375336835050" style={{ color: 'black' }}>
                                +375 (33) 683-50-50
                            </a>
                        </li>
                        <li className="number-operator">
                            <img src={life} width={30} alt="Life" />
                            <a href="tel:+375256835050" style={{ color: 'black' }}>
                                +375 (25) 683-50-50
                            </a>
                        </li>
                    </ul>
                </div>
                <p>
                    В таблице ниже вы можете ознакомиться с расписанием рейсов, временем в пути и стоимостью поездок.
                </p>
            </div>
            <div className="bus-info">
                <h2>Автобусы в аэропорты Москвы: Внуково, Домодедово, Шереметьево – ежедневно</h2>
            </div>
            <table className="transfer-table">
                <thead>
                    <tr>
                        <th>Откуда</th>
                        <th>
                            <div className="dollar-trans">
                                В обе стороны <img src={dollar} width={30} alt="Dollar" />
                            </div>
                        </th>
                        <th>
                            <div className="dollar-trans">
                                В одну сторону <img src={dollar} width={30} alt="Dollar" />
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Минск</td>
                        <td>280.00 бел. руб.</td>
                        <td>140.00 бел. руб.</td>
                    </tr>
                </tbody>
            </table>
            <div className="bus-info">
                <h2>Автобусы в аэропорт Жуковский – ежедневно</h2>
            </div>
            <table className="transfer-table">
                <thead>
                    <tr>
                        <th>Откуда</th>
                        <th>
                            <div className="dollar-trans">
                                В обе стороны <img src={dollar} width={30} alt="Dollar" />
                            </div>
                        </th>
                        <th>
                            <div className="dollar-trans">
                                В одну сторону <img src={dollar} width={30} alt="Dollar" />
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Минск</td>
                        <td>300.00 бел. руб.</td>
                        <td>150.00 бел. руб.</td>
                    </tr>
                </tbody>
            </table>

            <div className="transfer-details">
                <div className="dropdown-section">
                    <h2 onClick={() => toggleDropdown(0)}>
                        К путешествию готовы? <span>{activeIndex === 0 ? '-' : '+'}</span>
                    </h2>
                    {activeIndex === 0 && (
                        <div className="dropdown-content">
                            <p>
                                Только вот вылет к желанному месту отдыха не из Минска? В такой ситуации поможет трансфер в аэропорты Москвы. Воспользуйтесь нашей услугой, чтобы не ломать голову о том, как добраться к месту вылета на любимый курорт!
                            </p>
                        </div>
                    )}
                </div>

                <div className="dropdown-section">
                    <h2 onClick={() => toggleDropdown(1)}>
                        Что такое трансфер? <span>{activeIndex === 1 ? '-' : '+'}</span>
                    </h2>
                    {activeIndex === 1 && (
                        <div className="dropdown-content">
                            <p>
                                Сейчас модное словечко "трансфер" используется во многих сферах деятельности. В переводе с английского «transfer» означает «перемещение». В туризме оно обозначает услугу по доставке туриста в аэропорты к месту вылета, а также из аэропорта к месту отдыха (отели, гостиницы и т.п.). В обратном направлении, естественно, тоже.
                            </p>
                            <p>
                                Наша компания также предлагает своим туристам данную услугу. Причем трансфер по прилету из аэропорта до гостиницы, а затем из гостиницы в аэропорт уже включен в стоимость путевки, если вы купите ее у нас. Мы же предлагаем еще один вид трансфера – трансфер в аэропорт Москвы и трансфер в аэропорт Киева, когда вылет на курорт осуществляется не из Беларуси.
                            </p>
                        </div>
                    )}
                </div>

                <div className="dropdown-section">
                    <h2 onClick={() => toggleDropdown(3)}>
                        Трансфер из Минска в Москву <span>{activeIndex === 3 ? '-' : '+'}</span>
                    </h2>
                    {activeIndex === 3 && (
                        <div className="dropdown-content">
                            <p>
                                В аэропорты Москвы наши туристы, как правило, стремятся с целью вылета на отдых в Таиланд, Вьетнам и на ГОА. Мы поможем вам быстро и с комфортом добраться до аэропортов Москвы, чтобы оттуда вы смогли вылететь к месту отдыха.
                            </p>
                            <p>
                                Трансфер Минск-Москва осуществляется на комфортабельных автобусах, причем несколько раз в день. В нашем распоряжении есть вместительные автобусы, а также микроавтобусы. Общая вместимость – от 12 до 40 мест. Микроавтобусы – отличный вариант для тех, кто путешествует к месту вылета небольшой группой. Все автобусы оборудованы кондиционером, чтобы вам было комфортно летом. В зимний период в них работают обогреватели. Сопровождение группы предусмотрено. Наш специалист помогает пройти границу, ориентирует в аэропорту. На связи с вами также будет наш диспетчер.
                            </p>

                            <p>
                                Мы организуем трансфер из Минска в Москву, а также из других городов, как в одну сторону, так и в обе. По стоимости вас сориентирует таблица ниже.
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TransferInfo;