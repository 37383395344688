// src/pages/Main/Main.js
import React, { useState, useEffect } from "react";
import "./Visa.css";

import Poland from "../../assets/images/visa/poland.png";
import british from "../../assets/images/visa/british.png";
import germany from "../../assets/images/visa/germany.png";
import goa from "../../assets/images/visa/goa.png";
import greece from "../../assets/images/visa/greece.png";
import italy from "../../assets/images/visa/italy.png";
import spain from "../../assets/images/visa/spain.png";
import usa from "../../assets/images/visa/usa.png";

import documents from "../../assets/images/visa/documents.png";
import face from "../../assets/images/visa/face.png";
import inj from "../../assets/images/visa/inj.png";
import delivery from "../../assets/images/visa/delivery.png";
import AdvantagesSection from "./AdvantagesSection";
import happy from "../../assets/images/visa/happy.png";
import Banner from "../../components/Banner/Banner";
import BackCallPost from "../../components/BackCallPost/BackCallPost";

const visaCardsData = [
  {
    title: "Виза в Польшу",
    img: Poland,
    type: (
      <>
        <strong>Тип подачи:</strong> личная
      </>
    ),
    validity: (
      <>
        <strong>Срок действия визы:</strong> 180 дней/ 1 год
      </>
    ),
    stay: (
      <>
        <strong>Дней пребывания по визе:</strong> 180 дней / 365 дней
      </>
    ),
    processingTime: (
      <>
        <strong>Период оформления документов:</strong> от 1 дня до 1 месяца
      </>
    ),
    issuanceTime: (
      <>
        <strong>Срок изготовления визы:</strong> 7 дней
      </>
    ),
    fee: (
      <>
        <strong>Визовый сбор:</strong> 11 евро
      </>
    ),
    tel: (
      <>
        <strong>Услуги по оформлению::</strong> +375-29-683-50-50
      </>
    ),
  },
  {
    img: spain,
    title: "Виза в Испанию",
    type: (
      <>
        <strong>Тип подачи:</strong> личная
      </>
    ),
    validity: (
      <>
        <strong>Срок действия визы:</strong> до 2 лет
      </>
    ),
    stay: (
      <>
        <strong>Дней пребывания по визе:</strong> до 90 дней
      </>
    ),
    processingTime: (
      <>
        <strong>Период оформления документов:</strong> 1 день
      </>
    ),
    issuanceTime: (
      <>
        <strong>Срок изготовления визы:</strong> 14 дней
      </>
    ),
    fee: (
      <>
        <strong>Консульский сбор:</strong> 35 евро <br />
      </>
    ),
    fee2: (
      <>
        <strong>Сервисный сбор:</strong> 17 евро
      </>
    ),
    tel: (
      <>
        <strong>Услуги по оформлению::</strong> +375-29-683-50-50
      </>
    ),
  },
  {
    img: greece,
    title: "Виза в Грецию",
    type: (
      <>
        <strong>Тип подачи:</strong> личная
      </>
    ),
    validity: (
      <>
        <strong>Срок действия визы:</strong> до 2 лет
      </>
    ),
    stay: (
      <>
        <strong>Дней пребывания по визе:</strong> 90 дней в полугодие
      </>
    ),
    processingTime: (
      <>
        <strong>Период оформления документов:</strong> от 1 до 14 дней
      </>
    ),
    issuanceTime: (
      <>
        <strong>Срок изготовления визы:</strong> до 14 дней
      </>
    ),
    fee: (
      <>
        <strong>Консульский сбор:</strong> 35 евро
      </>
    ),
    fee2: (
      <>
        <strong>Сервисный сбор:</strong> 30 евро
      </>
    ),
    tel: (
      <>
        <strong>Услуги по оформлению::</strong> +375-29-683-50-50
      </>
    ),
  },
  {
    img: italy,
    title: "Виза в Италию",
    type: (
      <>
        <strong>Тип подачи:</strong> личная
      </>
    ),
    validity: (
      <>
        <strong>Срок действия визы:</strong> до 5 лет
      </>
    ),
    stay: (
      <>
        <strong>Дней пребывания по визе:</strong> 180 дней (разрешено пребывание
        до 90 дней в полугодие)
      </>
    ),
    processingTime: (
      <>
        <strong>Период оформления документов:</strong> 5 дней
      </>
    ),
    issuanceTime: (
      <>
        <strong>Срок изготовления визы:</strong> 14 дней
      </>
    ),
    fee: (
      <>
        <strong>Консульский сбор:</strong> 35 евро
      </>
    ),
    fee2: (
      <>
        <strong>Сервисный сбор:</strong>14 евро
      </>
    ),
    tel: (
      <>
        <strong>Услуги по оформлению::</strong> +375-29-683-50-50
      </>
    ),
  },
  {
    img: germany,
    title: "Виза в Германию",
    type: (
      <>
        <strong>Тип подачи:</strong> личная
      </>
    ),
    validity: (
      <>
        <strong>Срок действия визы:</strong> до 2 лет
      </>
    ),
    stay: (
      <>
        <strong>Дней пребывания по визе:</strong> 90 дней в полугодие
      </>
    ),
    processingTime: (
      <>
        <strong>Период оформления документов:</strong> от 1 до 14 дней
      </>
    ),
    issuanceTime: (
      <>
        <strong>Срок изготовления визы:</strong> от 10 дней
      </>
    ),
    fee: (
      <>
        <strong>Консульский сбор:</strong> 35 евро
      </>
    ),
    tel: (
      <>
        <strong>Услуги по оформлению::</strong> +375-29-683-50-50
      </>
    ),
  },
  {
    img: goa,
    title: "Виза в Гоа",
    type: (
      <>
        <strong>Тип подачи:</strong> личная
      </>
    ),
    validity: (
      <>
        <strong>Срок действия визы:</strong> до 30 дней с момента выхода из
        посольства
      </>
    ),
    stay: (
      <>
        <strong>Дней пребывания по визе:</strong> под поездку
      </>
    ),
    processingTime: (
      <>
        <strong>Период оформления документов:</strong> 1 день
      </>
    ),
    issuanceTime: (
      <>
        <strong>Срок изготовления визы:</strong> до 7 дней
      </>
    ),
    fee: (
      <>
        <strong>Консульский сбор:</strong> 25 долларов США
      </>
    ),
    tel: (
      <>
        <strong>Услуги по оформлению::</strong> +375-29-683-50-50
      </>
    ),
  },
  {
    img: british,
    title: "Виза в Великобританию",
    type: (
      <>
        <strong>Тип подачи:</strong> личная
      </>
    ),
    validity: (
      <>
        <strong>Срок действия визы:</strong> от 6 месяцев до 10 лет
      </>
    ),
    stay: (
      <>
        <strong>Дней пребывания по визе:</strong> до 180 дней
      </>
    ),
    processingTime: (
      <>
        <strong>Период оформления документов:</strong> от 5 до 14 дней
      </>
    ),
    issuanceTime: (
      <>
        <strong>Срок изготовления визы:</strong> от 5 до 14 дней
      </>
    ),
    fee: (
      <>
        <strong>Консульский сбор:</strong> от 118 до 1085 долларов США
      </>
    ),
    tel: (
      <>
        <strong>Услуги по оформлению::</strong> +375-29-683-50-50
      </>
    ),
  },
  {
    title: "Виза в США",
    img: usa,
    type: (
      <>
        <strong>Тип подачи:</strong> личная
      </>
    ),
    validity: (
      <>
        <strong>Срок действия визы:</strong> 1 год
      </>
    ),
    stay: (
      <>
        <strong>Дней пребывания по визе:</strong> 180 дней
      </>
    ),
    processingTime: (
      <>
        <strong>Период оформления документов:</strong> от 5 до 14 дней
      </>
    ),
    issuanceTime: (
      <>
        <strong>Срок изготовления визы:</strong> от 1 дня до 4 недель
      </>
    ),
    fee: (
      <>
        <strong>Консульский сбор:</strong> 160 долларов США
      </>
    ),
    tel: (
      <>
        <strong>Услуги по оформлению::</strong> +375-29-683-50-50
      </>
    ),
  },
];

const Visa = () => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
          }
        });
      },
      { threshold: 0.1 }
    );

    const elements = document.querySelectorAll(".fade-in");
    elements.forEach((el) => observer.observe(el));

    return () => {
      elements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  return (
    <div className="visa-info">
      <div className="contact-info fade-in">
        <h1>Мы открыли первый в Минске визовый дискаунтер</h1>

        <h2>
          Подготовим полный пакет документов для оперативной подачи и получения
          визы с гарантией 99,8%!
        </h2>

        <p>
          Основная цель MakeVisa.by — сделать процесс получения визы максимально
          простым и доступным для всех. Мы предлагаем конкурентоспособные цены и
          гибкие условия оплаты, а также гарантируем прозрачность и честность на
          каждом этапе сотрудничества. Доверьтесь нам, и мы позаботимся о том,
          чтобы ваш визовый процесс прошел без проблем и задержек. На нашем
          сайте вы найдете всю необходимую информацию о типах виз, требованиях к
          документам и сроках их получения.
        </p>
      </div>
      <section className="right-section right-section-visa fade-in">
        <div className="grid-big-section right-border grid-visa">
          <div className="feature">
            <img src={documents} alt="Icon" />
            <div className="feature-text">
              <h3>Подготовим документы</h3>
              <p>
                Избавим вас от риска допустить ошибку и потерять деньги, получив
                отказ!
              </p>
            </div>
          </div>
          <div className="feature">
            <img src={face} alt="Icon" />
            <div className="feature-text">
              <h3>Сделаем фото</h3>
              <p>Не нужно бегать по фотосалонам, сделаем все на месте!</p>
            </div>
          </div>

          <div className="feature">
            <img src={inj} alt="Icon" />
            <div className="feature-text">
              <h3>Оформим страховку</h3>
              <p>
                Надежное страхование от проверенного страхового полиса прямо у
                нас в офисе!
              </p>
            </div>
          </div>
          <div className="feature">
            <img src={delivery} alt="Icon" />
            <div className="feature-text">
              <h3>Доставим документы</h3>
              <p>
                Привезем документы домой или на работу по Минску или отправим их
                почтой по РБ!
              </p>
            </div>
          </div>
        </div>
      </section>
      <AdvantagesSection />
      <div className="column-visa fade-in">
        {visaCardsData.map((card, index) => (
          <div className="card-visa" key={index}>
            <div className="followers-visa">
              <h3>{card.title}</h3>
              <img src={card.img} className="img-visa" alt={card.title} />
              <p>{card.type}</p>
              <p>{card.validity}</p>
              <p>{card.stay}</p>
              <p>{card.processingTime}</p>
              <p>{card.issuanceTime}</p>
              <p>{card.fee}</p>
              <p>{card?.fee2 && ""}</p>
              <p>{card.tel}</p>
            </div>
            <BackCallPost
              isVisa={true}
              buttonText={`Оформить Визу`}
              secondText={`Заявка на оформление ${card.title}`}
            />
          </div>
        ))}
      </div>
      <Banner
        reverse={false}
        isVisa={true}
        img={happy}
        text="Остались вопросы по оформлению визы?"
      />
    </div>
  );
};

export default Visa;
