// src/pages/Main/Main.js
import React, { useState, useEffect } from "react";

import OwnerSection from "../../components/Owner/Owner";
import CompanyHistory from "../../components/Owner/CompanyHistory/index.js";
import OurMission from "../../components/Owner/OurMission.js";
import Gallery from "../../components/Owner/Gallery/Gallery.jsx";

const Company = () => {
 

  return (
    <div>
      <OurMission />
      <OwnerSection />
      <CompanyHistory />
      <Gallery />
    </div>
  );
};

export default Company;
