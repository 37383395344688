// src/components/Loader/Loader.js
import React from "react";
import "./Loader.css";
import logo from "../../assets/images/white.png";
import Spinner from "./Spinner";

const Loader = () => {
  return (
    <div className="loader">
      <img src={logo} className="logo-loader" alt="Loading..." />
      <Spinner />
    </div>
  );
};

export default Loader;
