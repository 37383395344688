// src/pages/Main/Main.js
import React, { useState, useEffect } from "react";
import HeroSection from "../../components/HeroSection/HeroSection";
import Countries from "../../components/Countries/Countries";
import TourvisorHotTours from "../../components/TourWidget/index";
import HotTourWidget from "../../components/HotTourWidget/index";
import Loader from "../../components/Loader/Loader";
import WhyUs from "../../components/WhyUs/WhyUs";
import Gallery from "../../components/Gallery/Gallery";
import ReviewsSection from "../../components/ReviewsSection/ReviewsSection";
import SupportSection from "../../components/SupportSection/SupportSection";
import PartnersSection from "../../components/PartnersSection/PartnersSection";
import OfficeSection from "../../components/OfficeSection/OfficeSection";
import BlockSection from "../../components/BlockSection/BlockSection";
import HeroSectionMobile from "../../components/HeroSection/HeroSectionMobile";
import Banner from "../../components/Banner/Banner";
import Banner1 from "../../assets/images/banners/banner1.png";
import BackCallPost from "../../components/BackCallPost/BackCallPost";
const Main = () => {
  const [loading, setLoading] = useState(true);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [tourvisorLoaded, setTourvisorLoaded] = useState(false);
  const [hotTourLoaded, setHotTourLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showBackCallPost, setShowBackCallPost] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => setIsMobile(window.innerWidth <= 768);
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);
    return () => window.removeEventListener("resize", checkIsMobile);
  }, []);

  window.addEventListener("load", function () {
    // Устанавливаем таймер на 20 секунд (20000 миллисекунд)
    setTimeout(function () {
      // Находим элемент кнопки
      const button = document.querySelector(".text-trigger");
      console.log(button, "go");
      if (button) {
        // Эмулируем клик на кнопке
        button.click();
        console.log("Клик выполнен на кнопке .TVBGradient");
      } else {
        console.log("Кнопка не найдена");
      }
    }, 20000); // 20000 миллисекунд = 20 секунд
  });

  useEffect(() => {
    const handleError = (message, source, lineno, colno, error) => {
      console.error("Caught an error:", message, source, lineno, colno, error);
      return true; // Prevents the default browser error handling
    };

    window.onerror = handleError;

    return () => {
      window.onerror = null; // Cleanup on component unmount
    };
  }, []);

  const handleAllLoaded = () => {
    if (!isMobile ? videoLoaded && tourvisorLoaded : tourvisorLoaded) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowBackCallPost(true);
    }, 20000); // 20 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {loading && <Loader />}
      {isMobile ? (
        <HeroSectionMobile />
      ) : (
        <HeroSection
          onVideoLoaded={() => {
            setVideoLoaded(true);
            handleAllLoaded();
          }}
        />
      )}
      <TourvisorHotTours
        onWidgetLoaded={() => {
          setTourvisorLoaded(true);
          handleAllLoaded();
        }}
      />{" "}
      <HotTourWidget
        onWidgetLoaded={() => {
          setHotTourLoaded(true);
          handleAllLoaded();
        }}
      />
      {!loading && <Countries />}
      <div style={{ marginTop: 50, marginBottom: 50 }}>
        <Banner
          reverse={true}
          img={Banner1}
          isVisa={false}
          text={"Тысячи предложений по разными направлениям"}
        />
      </div>
      <BlockSection />
      <WhyUs />
      <ReviewsSection />
      {/* <Gallery onWidgetLoaded={() => { setHotTourLoaded(true); handleAllLoaded(); }} /> */}
      <SupportSection />
      <PartnersSection />
      {/* {showBackCallPost && <BackCallPost />} */}
    </div>
  );
};

export default Main;
