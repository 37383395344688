import React from "react";
import "./Footer.css";
import logo from "../../assets/images/white.png";
import viza from "../../assets/images/viza.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-top">
          <div className="footer-column">
            <img src={logo} alt="logo" className="footer-logo" />
            <p className="logo-text">
              ООО «Маяна Трэвел» - сеть дискаунтеров горящих туров
              <br />
              УНП 193523091
            </p>
            <p>Время работы офисов: Пн-Вс 10.00-19.00</p>
          </div>
          <div className="footer-column phones-col">
            <h3>Контакты</h3>
            <div className="contact-list">
              <p>
                Минск: <a href="tel:+375296835050">+375-(29)-683-50-50</a>
              </p>
              <p>
                Брест: <a href="tel:+375447276060">+375-(44)-727-60-60</a>
              </p>
              <p>
                Гродно: <a href="tel:+375445579090">+375-(44)-557-90-90</a>
              </p>
              <p>
                Могилев: <a href="tel:+375293709595">+375-(29)-370-95-95</a>
              </p>
              {/* <p>
                Борисов: <a href="tel:+375293236877">+375-(29)-323-68-77</a>
              </p> */}
            </div>
          </div>
          <div className="footer-column emergency-column">
            <h3>Телефон экстренной связи </h3>
            <p className="emergency-phone">
              {" "}
              <a className="red-span1" href="tel:+375296835050" style={{ color: "red" }}>
                +375-29-683-50-50 📞{" "}
              </a>
            </p>

            <p>Для наших клиентов (24/7)</p>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="social-media">
            <a href="https://vk.com/maketravelby" className="social-icon vk">
              ВКонтакте
            </a>
            <a
              href="https://www.instagram.com/maketravel.by/"
              className="social-icon instagram"
            >
              Instagram
            </a>
            <a
              href="https://t.me/maketravelby"
              className="social-icon telegram"
            >
              Telegram
            </a>
          </div>
          <p className="desc-footer">
            ООО «Маяна Трэвел» - сеть дискаунтеров горящих туров УНП 193523091 *
            Цены носят информативный характер. Все платежи проводятся
            исключительно в белорусских рублях.
          </p>
          <img src={viza} style={{ width: "100%", maxWidth: 400 }} alt="viza" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
