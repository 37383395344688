import React, { useState, useEffect } from "react";
import "./HeroSection.css";
import logo from "../../assets/images/white.png";

const HeroSectionMobile = ({ onVideoLoaded }) => {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  return (
    <section className="hero">
      <div className="hero-gallery">
        {/* You can add background images for the gallery here */}
        <div className="hero-image hero-image-1"></div>
        {/* <div className="hero-image hero-image-2"></div> */}
      </div>

      <div className="hero-overlay  ">
        <img src={logo} className="mobile-hero" alt="Logo" />
      </div>
    </section>
  );
};

export default HeroSectionMobile;
