// src/pages/Main/Main.js
import React, { useState, useEffect } from "react";

import ClientSection from "../../components/ClientSection/ClientSection.js";
import ReviewsGallery from "../../components/ReviewsGallery/ReviewsGallery.js";

const Reviews = () => {
 
  return (
    <div>
      <ClientSection />
      <ReviewsGallery/>
    </div>
  );
};

export default Reviews;
