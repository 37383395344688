import React, { useEffect } from 'react';
import './MiceTourism.css';
import miceImage from '../../assets/images/mice.png';
import conf from '../../assets/images/mice/conf.png';
import exp from '../../assets/images/mice/exp.png';
import meeting from '../../assets/images/mice/meeting.png';
import travel from '../../assets/images/mice/travel.png';
import Gallery from '../../components/Gallery/Gallery';
import Banner from '../../components/Banner/Banner';
import Banner1 from "../../assets/images/banners/banner2.png";

const MiceTourism = () => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('show');
          }
        });
      },
      { threshold: 0.1 }
    );

    const elements = document.querySelectorAll('.fade-in');
    elements.forEach((el) => observer.observe(el));

    return () => {
      elements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  return (
    <div className="mice-tourism">
      <div className={"mice-tourism-contaier"}>
        <div className="intro-section fade-in">
          <h1>MICE-туризм</h1>
          <img src={miceImage} alt="MICE Туризм" className="mice-image" />
          <p>
            Важное направление деятельности компании Make Travel – MICE-туризм. Мы организуем мероприятия любого масштаба, от небольших корпоративных встреч до международных конференций и выставок. Наши специалисты обеспечивают высокий уровень сервиса и индивидуальный подход к каждому клиенту.
          </p>
          <p>
            MICE (Meetings, Incentives, Conferences, Exhibitions) – это вид туризма, связанный с организацией и проведением различных деловых мероприятий. Это направление охватывает широкий спектр услуг, включая организацию встреч, проведение стимуляционных поездок, конференций и выставок.
          </p>
        </div>

        <div className="services-section fade-in">
          <h2>Основные услуги MICE-туризма:</h2>
        </div>
        <section className="right-section right-section-visa mice-gallery fade-in">
          <div className="grid-big-section grid-visa mice-greed">
            <div className="feature fade-in">
              <img src={meeting} alt="Icon" />
              <div className="feature-text mice-feature">
                <h3>Meetings</h3>
                <p>
                  Организация встреч и переговоров на международном уровне
                </p>
              </div>
            </div>
            <div className="feature fade-in">
              <img src={travel} alt="Icon" />
              <div className="feature-text mice-feature">
                <h3>Incentives</h3>
                <p>Cтимуляционные поездки для сотрудников и партнеров</p>
              </div>
            </div>
            <div className="feature mice-feature fade-in">
              <img src={conf} alt="Icon" />
              <div className="feature-text mice-feature">
                <h3>Conferences</h3>
                <p>
                  Oрганизация конференций и семинаров с участием специалистов мирового уровня
                </p>
              </div>
            </div>
            <div className="feature fade-in">
              <img src={exp} alt="Icon" />
              <div className="feature-text mice-feature">
                <h3>Exhibitions</h3>
                <p>
                  Oрганизация выставок и имиджевых мероприятий
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className="services-section fade-in">
          <h2>Почему выбирают MICE-туризм с Make Travel?</h2>
          <ul>
            <li><strong>Индивидуальный подход</strong> – мы понимаем, что каждое корпоративное мероприятие уникально, поэтому предлагаем решения, максимально соответствующие потребностям и целям вашего бизнеса.</li>
            <li><strong>Полный спектр услуг</strong> – мы берем на себя все организационные вопросы, начиная с выбора площадки и заканчивая логистикой и сопровождением участников.</li>
            <li><strong>Профессиональная команда</strong> – наша команда состоит из опытных специалистов, которые знают все нюансы организации корпоративных мероприятий и обеспечивают их успешное проведение на любом уровне.</li>
            <li><strong>Гибкость и адаптация</strong> – мы готовы адаптироваться под любые изменения и требования, чтобы ваше мероприятие прошло на высшем уровне и оставило только положительные впечатления.</li>
          </ul>
        </div>

        <Gallery />

        <div className="explanation-section fade-in">
          <h3>MICE-туризм с Make Travel</h3>
          <p>
            это гарантия успешного и профессионального проведения ваших корпоративных мероприятий. Независимо от масштаба и сложности задачи, мы предлагаем индивидуальные решения, которые помогут вам достичь поставленных целей и укрепить позиции вашего бизнеса на рынке.
            Свяжитесь с нами, и мы разработаем для вас программу, которая превзойдет все ожидания!
          </p>
        </div>
        <Banner img={Banner1} text="Оставьте заявку на групповое корпоративное путешествие" isVisa={false} />
      </div>
    </div>
  );
};

export default MiceTourism;