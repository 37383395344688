import React, { useEffect } from 'react';
import './PartnersSection.css';

import p1 from '../../assets/images/partners/p1.png';
import p2 from '../../assets/images/partners/p2.png';
import p3 from '../../assets/images/partners/p3.png';
import p4 from '../../assets/images/partners/p4.svg';
import p5 from '../../assets/images/partners/p5.png';
import p6 from '../../assets/images/partners/p6.png';
import p7 from '../../assets/images/partners/p7.png';
import p8 from '../../assets/images/partners/p8.jpg';
import p9 from '../../assets/images/partners/p9.png';
import p10 from '../../assets/images/partners/p10.png';
import p11 from '../../assets/images/partners/p11.png';
import p12 from '../../assets/images/partners/p12.png';
import p13 from '../../assets/images/partners/p13.png';
import p14 from '../../assets/images/partners/p14.png';
import p15 from '../../assets/images/partners/p15.png';
import p16 from '../../assets/images/partners/p16.jpg';

const partners = [
  p1, p2, p3, p4,
  p5, p6, p7, p8,
  p9, p10, p11, p12,
  p13, p14, p15, p16
];

const PartnersSection = () => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          } else {
            entry.target.classList.remove('visible');
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    const elements = document.querySelectorAll('.partner-card');
    elements.forEach(element => observer.observe(element));

    return () => {
      elements.forEach(element => observer.unobserve(element));
    };
  }, []);

  return (
    <section className="partners-section">
      <h2>Наши Партнёры</h2>
      <div className="partners-grid">
        {partners.map((partner, index) => (
          <div key={index} className="partner-card">
            <img src={partner} alt={`Partner ${index + 1}`} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default PartnersSection;
