import React, { useEffect, useState } from "react";

import "./ClientSection.css";
import instagram from "../../../src/assets/images/revies/inst.png";
import vk from "../../../src/assets/images/revies/vk.png";
import reviewimg from "../../../src/assets/images/review1.png";
const ClientSection = () => {
  const [instagramFollowers, setInstagramFollowers] = useState(0);
  const [vkFollowers, setVkFollowers] = useState(0);

  useEffect(() => {
    const targetInstagram = 145000;
    const targetVk = 135000;
    const increment = 5000;
    const interval = 20;

    let instagramCount = 0;
    let vkCount = 0;

    const instagramInterval = setInterval(() => {
      instagramCount += increment;
      if (instagramCount >= targetInstagram) {
        instagramCount = targetInstagram;
        clearInterval(instagramInterval);
      }
      setInstagramFollowers(instagramCount);
    }, interval);

    const vkInterval = setInterval(() => {
      vkCount += increment;
      if (vkCount >= targetVk) {
        vkCount = targetVk;
        clearInterval(vkInterval);
      }
      setVkFollowers(vkCount);
    }, interval);

    return () => {
      clearInterval(instagramInterval);
      clearInterval(vkInterval);
    };
  }, []);

  return (
    <div className="client-section">
      <div className="client-container">
        <div className="client-section-rew">
          <h2>Наши клиенты — наша гордость. </h2>{" "}
          <div className="intro-section">
            <img
              src={reviewimg}
              alt="MICE Туризм"
              className="mice-image review-img"
            />
          </div>
          <p>
            Их отзывы — наше вдохновение. За время нашей работы мы смогли
            собрать суммарную аудиторию более
            <span style={{ fontWeight: "bold" }}> 250.000 </span> человек во
            всех социальных сетях.
          </p>
        </div>

        <div className="columns">
          <div className="column">
            <div className="card">
              <img
                src={instagram}
                alt="Instagram Followers"
                className="social-image"
              />
              <div className="followers">
                <h3>Instagram</h3>
                <p>144 000</p>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="card">
              <img src={vk} alt="VK Followers" className="social-image" />
              <div className="followers">
                <h3>ВКонтакте</h3>
                <p>131 000</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientSection;
