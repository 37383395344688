import React, { useEffect } from 'react';
import './WhyUs.css';
import logo from '../../assets/images/white_logo.svg';
import { Link } from 'react-router-dom';
import money from '../../assets/images/icons/money.png';
import con from '../../assets/images/icons/con.png';
import way from '../../assets/images/icons/way.png';
import weekend from '../../assets/images/icons/weekend.png';
import first from '../../assets/images/icons/first.png';
import safe from '../../assets/images/icons/safe.png';
import inst from '../../assets/images/social/instlogo.png';
import vk from '../../assets/images/social/vklogo.png';

const WhyUs = () => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          } else {
            entry.target.classList.remove('visible');
          }
        });
      },
      {
        threshold: 0.1, // Процент видимости элемента, чтобы он считался видимым
      }
    );

    const elements = document.querySelectorAll('.fade-in');
    elements.forEach(element => observer.observe(element));

    return () => {
      elements.forEach(element => observer.unobserve(element));
    };
  }, []);

  return (
    <div className="container-why">
      <div className="content-why">
        <section className="left-section fade-in">
          <h2>Почему Make Travel?</h2>
          <h1>
            <span style={{ color: "#eac700" }}>Make Travel</span> крупнейшая туристическая компания в Беларуси
          </h1>
          <p>
            Официальный День Рождения компании Make Travel – 10 августа 2015 года открылся наш первый офис. На сегодняшний день компания насчитывает 17 офисов в Беларуси и Украине.
          </p>
          <div className="social-links">
            <Link to="https://www.instagram.com/maketravel.by/">
              <img src={inst} alt="Instagram" className="social-icon" />
              <div>
                <p className='link-title'>Страница в Instagram</p>
                <p>Более 145.000 подписчиков</p>
              </div>
            </Link>
            <Link to="https://vk.com/maketravelby">
              <img src={vk} alt="ВКонтакте" className="social-icon" />
              <div>
                <p className='link-title'>Группа во Вконтакте</p>
                <p>Более 135.000 подписчиков</p>
              </div>
            </Link>
          </div>
          <Link to="/o-kompanii/about-us">
            <button className="explore-button">Узнать о компании</button>
          </Link>
        </section>
        <section className="right-section fade-in">
          <div className="grid-big-section right-border">
            <div className="feature">
              <img src={money} alt="Icon" />
              <div className="feature-text">
                <h3>Лучшие цены в Беларуси!</h3>
                <p>Если Вы найдете один из наших туров дешевле чем у нас, то мы предложим еще дешевле!</p>
              </div>
            </div>
            <div className="feature feature-border">
              <img src={weekend} alt="Icon" />
              <div className="feature-text">
                <h3>Работаем по выходным</h3>
                <p>Мы понимаем как тяжело в будний день выделить время на поездку в агентство и поэтому работаем в субботу и воскресенье.</p>
              </div>
            </div>
            <div className="feature">
              <img src={safe} alt="Icon" />
              <div className="feature-text">
                <h3>Надежность</h3>
                <p>Каждую поездку оформляем официально, выдаем чек и никогда не отказываемся от своих обязательств.</p>
              </div>
            </div>
          </div>
          <div className="grid-big-section mobile">
            <div className="feature">
              <img src={con} alt="Icon" />
              <div className="feature-text">
                <h3>Квалифицированная консультация</h3>
                <p>Все наши консультанты имеют высшее туристическое образование и отлично знают свое дело.</p>
              </div>
            </div>
            <div className="feature feature-border">
              <img src={way} alt="Icon" />
              <div className="feature-text">
                <h3>Правильный подход</h3>
                <p>Все наши сотрудники вежливы и внимательны к каждому клиенту, вне зависимости от его бюджета, вкусов и пожеланий.</p>
              </div>
            </div>
            <div className="feature">
              <img src={first} alt="Icon" />
              <div className="feature-text">
                <h3>Все лучшие в одной компании</h3>
                <p>Миллионы туров от десятков ведущих туроператоров и морские круизы во всех регионах мира.</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default WhyUs;