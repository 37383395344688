import React, { useState, useEffect } from "react";
import "./Owner.css";
import bogdanImage from "../../assets/images/about/bogdan11.png";

const OwnerSection = () => {
  const [auditore, setAuditore] = useState(0);
  const [years, setYears] = useState(0);
  const [reviews, setReviews] = useState(0);

  useEffect(() => {
    const targetAuditore = 250;
    const targetYears = 10;
    const targetReviews = 6;

    const increment1 = 50;
    const increment2 = 2;
    const increment3 = 1;

    const interval = 200;

    let audiCount = 0;
    let yearsCount = 0;
    let reviewCounts = 0;
    const auditoreInterval = setInterval(() => {
      audiCount += increment1;
      if (audiCount >= targetAuditore) {
        audiCount = targetAuditore;
        clearInterval(auditoreInterval);
      }
      setAuditore(audiCount);
    }, interval);

    const yearsInterval = setInterval(() => {
      yearsCount += increment2;
      if (yearsCount >= targetYears) {
        yearsCount = targetYears;
        clearInterval(yearsInterval);
      }
      setYears(yearsCount);
    }, interval);

    const setReviewsInterval = setInterval(() => {
      reviewCounts += increment3;
      if (reviewCounts >= targetReviews) {
        reviewCounts = targetReviews;
        clearInterval(setReviewsInterval);
      }
      setReviews(reviewCounts);
    }, interval);
    return () => {
      clearInterval(auditoreInterval);
      clearInterval(yearsInterval);
      clearInterval(setReviewsInterval);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    const elements = document.querySelectorAll(".owner-quote, .owner-info, .owner-stats");
    elements.forEach((element) => observer.observe(element));

    return () => {
      elements.forEach((element) => observer.unobserve(element));
    };
  }, []);

  return (
    <section className="owner-section">
      <div className="owner-container">
        <div className="owner-main">
          <div className="owner-quote">
            <p>
              "Когда я покупал туры, то часто сталкивался с тем, что сотрудники
              компаний массово толком не разбираются в странах, отелях, условиях
              проживания там и других весомых вещах, от которых зависит качество
              отдыха. Именно тогда я понял, что хочу сделать лучший сервис в
              сфере туризма в странах СНГ по отношению к клиентам и повысить
              экспертность в качестве отдыха. Как руководитель я достаточно
              строгий и требовательный. И все для того, чтобы наши клиенты
              получали самый лучший сервис, цену и качество отдыха. При всем при
              этом я много делаю для наших сотрудников и культивирую в них
              стремление к постоянному саморазвитию, заботясь опять-таки о
              высоких стандартах клиентского обслуживания!"
            </p>
          </div>
          <div className="owner-info">
            <img
              src={bogdanImage}
              alt="Богдан Цагойко"
              className="owner-image"
            />
            <div>
              <h3>Богдан Цагойко</h3>
              <p>Генеральный директор & CEO </p>
            </div>
          </div>
        </div>
        <div className="owner-stats">
          <div className="stat-item">
            <p>250K</p>
            <span>наша аудитория</span>
          </div>
          <div className="stat-item">
            <p>10+</p>
            <span>лет опыта работы</span>
          </div>
          <div className="stat-item">
            <p>6K</p>
            <span>положительных отзывов</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OwnerSection;
