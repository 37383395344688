import t1 from "../assets/images/pages/t1.jpeg";
import t2 from "../assets/images/pages/t2.jpeg";
import e1 from "../assets/images/pages/e1.jpeg";
import e2 from "../assets/images/pages/e2.jpeg";
import c1 from "../assets/images/pages/c1.jpeg";
import c2 from "../assets/images/pages/c2.jpeg";
import oae1 from "../assets/images/pages/oae1.jpeg";
import oae2 from "../assets/images/pages/oae2.jpeg";
import jo1 from "../assets/images/pages/jo1.jpeg";
import jo2 from "../assets/images/pages/jo2.jpeg";
import i1 from "../assets/images/pages/i1.jpeg";
import i2 from "../assets/images/pages/i2.jpeg";
import m1 from "../assets/images/pages/m1.jpeg";
import m2 from "../assets/images/pages/m2.jpeg";
import ta1 from "../assets/images/pages/ta1.jpeg";
import ta2 from "../assets/images/pages/ta2.jpeg";
import sp from "../assets/images/contries2/s1.jpg";
import g1 from "../assets/images/contries2/g1.jpg";
import gr from "../assets/images/contries2/gr1.png";
import qatar from "../assets/images/contries2/katar.jpg";
import china from "../assets/images/contries2/china.jpg";
import tailand from "../assets/images/contries2/tailand.jpg";
import italy from "../assets/images/contries2/italy.jpg";
import domikana from "../assets/images/contries2/domikana.jpeg";
import viet from "../assets/images/contries2/viet.png";
import mexico from "../assets/images/contries2/mexico.jpeg";
import india from "../assets/images/contries2/india.jpg";
import cyprs from "../assets/images/contries2/cyprs.png";
import goa from "../assets/images/contries2/goa.jpg";
import shrilanka from "../assets/images/contries2/shrilanka.png";
import mavriki from "../assets/images/contries2/mavriki.jpg";

const countriesData = {
  turkey: {
    name: "Турция",
    img1: t1,
    img2: t2,
    description: `Турция с MakeTravel - это идеальное место для вашего отдыха. Эта страна, где Восток встречается с Западом, предлагает захватывающие туры и путевки, наполненные историей и культурой. Стамбул, Анталия и Каппадокия - только часть мест, которые вы сможете исследовать с МейкТревел. Турция известна своими пляжами, традиционной кухней и знаменитым гостеприимством. 
    Забронируйте тур в Турцию через МейкТревел и ощутите восточное очарование, которым пропитана каждая улица.`,
    facts: [
      "В Турции находятся древнейшие поселения мира.",
      "Анталия - один из лучших пляжных курортов в мире.",
      "Хамамы Турции - традиционные бани, которые подарят вам незабываемое расслабление.",
    ],
  },
  egypt: {
    img1: e1,
    img2: e2,
    name: "Египет",
    description: `С MakeTravel, Египет откроет вам удивительный мир древних фараонов и пирамид. Эта страна известна своими легендарными достопримечательностями, такими как пирамиды Гизы и Великий Сфинкс. Забронируйте путевку в Египет с МейкТревел и отправляйтесь в путешествие по Нилу или на отдых в Шарм-эль-Шейх. Погружайтесь в богатую историю, открывая для себя секреты древней цивилизации.
    МейкТревел предоставляет лучшие туры в Египет для незабываемого путешествия.`,
    facts: [
      "Пирамиды Гизы - одно из семи чудес света.",
      "Египетский музей в Каире хранит бесценные артефакты.",
      "Красное море - одно из лучших мест для дайвинга.",
    ],
  },
  cuba: {
    img1: c1,
    img2: c2,
    name: "Куба",
    description: `Куба с MakeTravel - это живописные пляжи, ритмы сальсы и богатая история. Пусть МейкТревел станет вашим проводником в этот райский уголок Карибов. Куба славится своими ретро-автомобилями, колониальной архитектурой и уникальной атмосферой. 
    Забронируйте туры на Кубу через МейкТревел и откройте для себя Гавану, Варадеро и другие великолепные направления.`,
    facts: [
      "Гавана - столица Кубы, известная своими ретро-автомобилями и колониальной архитектурой.",
      "Варадеро - один из лучших курортов мира.",
      "Куба славится своими сигарами и румом.",
    ],
  },
  oae: {
    img1: oae1,
    img2: oae2,
    name: "ОАЭ",
    description: `Объединенные Арабские Эмираты с MakeTravel - это страна роскоши, небоскребов и пустыни. Забронируйте туры в ОАЭ через МейкТревел и наслаждайтесь ультрасовременными городами, такими как Дубай и Абу-Даби, а также захватывающими пустынными приключениями. ОАЭ - это место, где прошлое встречается с будущим. Откройте для себя уникальные ландшафты и насладитесь высочайшим уровнем сервиса с путевками от MakeTravel.`,
    facts: [
      "Бурдж-Халифа - самое высокое здание в мире.",
      "ОАЭ известны своими роскошными торговыми центрами и аквапарками.",
      "Пустынные сафари - одно из самых популярных развлечений для туристов.",
    ],
  },
  jordan: {
    img1: jo1,
    img2: jo2,
    name: "Иордания",
    description: `С MakeTravel, Иордания откроется вам как страна, наполненная древними чудесами и мистическими пустынями. Посетите Петру, одно из семи чудес света, и погрузитесь в историю. Забронируйте туры в Иорданию с МейкТревел и исследуйте древние города и величественные пустыни. Путевки в Иорданию откроют вам магию Востока и гостеприимство местных жителей.`,
    facts: [
      "Петра - одно из семи новых чудес света.",
      "Вади Рам - уникальная пустыня с бескрайними песчаными дюнами.",
      "Мертвое море - самое соленое озеро в мире.",
    ],
  },
  indonesion: {
    img1: i1,
    img2: i2,
    name: "Индонезия",
    description: `Индонезия с MakeTravel - это рай для любителей природы и культуры. Исследуйте Бали, Яву и Суматру с путевками от МейкТревел. Уникальные пейзажи, древние храмы и экзотическая кухня ждут вас. Забронируйте туры в Индонезию и ощутите гармонию природы и культуры в каждой детали.`,
    facts: [
      "Индонезия состоит из более 17 000 островов.",
      "Бали - это остров богов с потрясающими пляжами и храмами.",
      "Индонезийская кухня славится своим разнообразием.",
    ],
  },
  maldives: {
    img1: m1,
    img2: m2,
    name: "Мальдивы",
    description: `Мальдивы с MakeTravel - это идеальное место для тех, кто ищет покой и роскошь. Забронируйте путевки на Мальдивы через МейкТревел и насладитесь белоснежными пляжами и кристально чистыми водами. Незабываемые туры на Мальдивы включают роскошные курорты и удивительные подводные приключения.`,
    facts: [
      "Мальдивы состоят из более 1000 островов.",
      "Это одно из лучших мест в мире для пляжного отдыха.",
      "Мальдивы известны своими роскошными водными виллами.",
    ],
  },
  tanzania: {
    img1: ta1,
    img2: ta2,
    name: "Танзания",
    description: `С MakeTravel, Танзания предложит вам уникальные сафари и удивительные природные пейзажи. Забронируйте путевки в Танзанию с МейкТревел и отправляйтесь на сафари в Серенгети или поднимайтесь на Килиманджаро. Танзания - это страна, где природа впечатляет своим великолепием, а туры от MakeTravel сделают ваше путешествие незабываемым.`,
    facts: [
      "Серенгети - одно из лучших мест для наблюдения за дикой природой.",
      "Кратер Нгоронгоро - уникальная экосистема.",
      "Килиманджаро - самая высокая гора Африки.",
    ],
  },
  spain: {
    img1: sp,
    name: "Испания",
    description: `Испания с MakeTravel - это великолепные города, солнечные пляжи и насыщенная культура. Забронируйте туры в Испанию с МейкТревел и откройте для себя Барселону, Мадрид, Валенсию и другие города. Насладитесь испанской кухней, археологическими памятниками и фламенко-шоу. Путевки в Испанию от MakeTravel подарят вам незабываемый опыт.`,
    facts: [
      "Саграда Фамилия - один из самых известных соборов мира.",
      "Фламенко - символ испанской культуры.",
      "Паэлья - традиционное испанское блюдо, которое стоит попробовать.",
    ],
  },
  georgia: {
    img1: g1,
    name: "Грузия",
    description: `Грузия с MakeTravel - это страна, где природа и культура создают незабываемые впечатления. Забронируйте туры в Грузию с МейкТревел и откройте для себя Тбилиси, Мцхету и Сванетию. Путевки в Грузию подарят вам возможность насладиться местной кухней и гостеприимством. Исследуйте старинные города и величественные горы с MakeTravel.`,
    facts: [
      "Грузия - родина вина.",
      "Сванетия славится своими древними башнями.",
      "Тбилиси известен своими серными банями.",
    ],
  },
  mavriki: {
    img1: mavriki,
    name: "Маврикий",
    description: `Маврикий с MakeTravel - это роскошные пляжи, теплое море и непревзойденный отдых. Забронируйте путевки на Маврикий через МейкТревел и насладитесь райским отдыхом. Маврикий предлагает удивительные туры для тех, кто ищет покой и экзотику.`,
    facts: [
      "Маврикий - одно из лучших мест для роскошного отдыха.",
      "Тропический климат острова идеально подходит для пляжного отдыха.",
    ],
  },
  goa: {
    img1: goa,
    name: "Гоа",
    description: `Гоа с MakeTravel - это уникальный курорт Индии, предлагающий экзотику и пляжный отдых. Забронируйте туры в Гоа с МейкТревел и наслаждайтесь атмосферой свободы и покоя. Путевки в Гоа от MakeTravel подарят вам возможность расслабиться на лучших пляжах Индии.`,
    facts: [
      "Гоа славится своими пляжами и ночной жизнью.",
      "Здесь сочетается индийская и португальская культура.",
    ],
  },
  shrilanka: {
    img1: shrilanka,
    name: "Шри-Ланка",
    description: `Шри-Ланка с MakeTravel - это остров тысячи чудес. Забронируйте туры на Шри-Ланку через МейкТревел и исследуйте древние храмы, чайные плантации и тропические пляжи. Путевки на Шри-Ланку подарят вам уникальное приключение в сердце Азии.`,
    facts: [
      "Шри-Ланка славится своим чаем и уникальной природой.",
      "Это одно из лучших мест для пляжного отдыха и экотуризма.",
    ],
  },
  greece: {
    img1: gr,
    name: "Греция",
    description: `Греция с MakeTravel - это уникальная смесь древней истории, культурных сокровищ и великолепных пляжей. Забронируйте туры в Грецию через МейкТревел и исследуйте Афины, Санторини и Крит. Путевки в Грецию позволят вам наслаждаться греческой кухней и древними памятниками.`,
    facts: [
      "Акрополь в Афинах - символ древней Греции.",
      "Санторини известен своими потрясающими закатами.",
      "Греческий салат - одно из самых известных блюд Греции.",
    ],
  },
  qatar: {
    img1: qatar,
    name: "Катар",
    description: `Катар с MakeTravel - это ультрасовременные города, пустынные приключения и культурные достопримечательности. Забронируйте туры в Катар с МейкТревел и насладитесь архитектурными чудесами Дохи и культурным наследием страны. Путевки в Катар от MakeTravel подарят вам незабываемый отдых.`,
    facts: [
      "Доха - ультрасовременный город с множеством небоскребов.",
      "Катар известен своими пустынными сафари и катанием на верблюдах.",
    ],
  },
  china: {
    img1: china,
    name: "Китай",
    description: `Китай с MakeTravel - это величие древней цивилизации и современные мегаполисы. Забронируйте туры в Китай через МейкТревел и откройте для себя Пекин, Шанхай и Сиань. Путевки в Китай подарят вам возможность увидеть Великую китайскую стену и насладиться китайской кухней.`,
    facts: [
      "Великая китайская стена - одно из семи чудес света.",
      "Шанхай известен своей современной архитектурой.",
    ],
  },
  thailand: {
    img1: tailand,
    name: "Тайланд",
    description: `Тайланд с MakeTravel - это страна храмов, пляжей и экзотической кухни. Забронируйте туры в Тайланд с МейкТревел и откройте для себя Бангкок, Пхукет и Чианг Май. Путевки в Тайланд от MakeTravel подарят вам возможность насладиться азиатской экзотикой.`,
    facts: [
      "Бангкок - столица Тайланда с множеством храмов.",
      "Пхукет - один из самых популярных курортов Тайланда.",
    ],
  },
  italy: {
    img1: italy,
    name: "Италия",
    description: `Италия с MakeTravel - это страна искусства, истории и вкусной еды. Забронируйте туры в Италию с МейкТревел и посетите Рим, Флоренцию и Венецию. Путевки в Италию подарят вам незабываемые впечатления от посещения Колизея, Уффици и романтических прогулок по каналам Венеции.`,
    facts: [
      "Колизей - один из величайших памятников древнего мира.",
      "Флоренция - культурная столица Италии.",
    ],
  },
  dominicana: {
    img1: domikana,
    name: "Доминикана",
    description: `Доминикана с MakeTravel - это рай для пляжного отдыха. Забронируйте туры в Доминикану с МейкТревел и наслаждайтесь белоснежными пляжами и теплыми водами Карибского моря. Путевки в Доминикану подарят вам отдых на лучших курортах Карибского региона.`,
    facts: [
      "Пунта-Кана - один из самых популярных курортов Карибского региона.",
      "Доминикана славится своими пляжами и тропическим климатом.",
    ],
  },
  vietnam: {
    img1: viet,
    name: "Вьетнам",
    description: `Вьетнам с MakeTravel - это удивительное сочетание истории, природы и культуры. Забронируйте туры во Вьетнам с МейкТревел и откройте для себя Ханой, Хошимин и Халонг. Путевки во Вьетнам подарят вам уникальные впечатления от путешествия по этой удивительной стране.`,
    facts: [
      "Ханой - древняя столица с богатой историей.",
      "Халонг - одно из природных чудес света с тысячами известняковых островов.",
    ],
  },
  mexico: {
    img1: mexico,
    name: "Мексика",
    description: `Мексика с MakeTravel - это страна древних цивилизаций и потрясающих пляжей. Забронируйте туры в Мексику с МейкТревел и посетите Мехико, Канкун и Чичен-Ицу. Путевки в Мексику подарят вам возможность насладиться культурными и природными сокровищами этой удивительной страны.`,
    facts: [
      "Чичен-Ица - одно из семи чудес света.",
      "Канкун - один из самых популярных курортов Мексики.",
    ],
  },
  india: {
    img1: india,
    name: "Индия",
    description: `Индия с MakeTravel - это страна древней истории и уникальной культуры. Забронируйте туры в Индию с МейкТревел и откройте для себя Дели, Мумбаи и Гоа. Путевки в Индию подарят вам возможность окунуться в мир древних цивилизаций и насладиться уникальной индийской кухней.`,
    facts: [
      "Тадж-Махал - одно из величайших сооружений в мире.",
      "Гоа - популярный курорт на западном побережье Индии.",
    ],
  },
  cyprus: {
    img1: cyprs,
    name: "Кипр",
    description: `Кипр с MakeTravel - это солнечные пляжи и древняя история. Забронируйте туры на Кипр с МейкТревел и откройте для себя Никосию, Лимассол и Пафос. Путевки на Кипр подарят вам возможность насладиться местной культурой и кухней.`,
    facts: [
      "Никосия - последняя разделенная столица в мире.",
      "Пафос - древний город, включенный в список наследия ЮНЕСКО.",
    ],
  },
};

export default countriesData;