// src/components/AllDestinations.js
import React from "react";
import { Link } from "react-router-dom";
import "./AllDestinations.css";
import Banner from "../../components/Banner/Banner";
import Banner1 from "../../assets/images/banners/banner2.png";

const destinations = [
  {
    name: "Египет",
    places: "347 предложений",
    className: "egypt",
    path: "egypt",
  },
  {
    name: "Турция",
    places: "156 предложений",
    className: "turkey",
    path: "turkey",
  },
  {
    name: "Куба",
    places: "48 предложений",
    className: "cuba",
    path: "cuba",
  },
  {
    name: "Индонезия (Бали)",
    places: "173 предложений",
    className: "indonesion",
    path: "indonesion",
  },
  {
    name: "Катар",
    places: "43 предложений",
    className: "qatar",
    path: "qatar",
  },
  {
    name: "Тайланд",
    places: "124 предложений",
    className: "thailand",
    path: "thailand",
  },
  {
    name: "ОAЭ",
    places: "184 предложений",
    className: "oae",
    path: "oae",
  },

  {
    name: "Шри-ланка",
    places: "123 предложения",
    className: "shrilanka",
    path: "shrilanka",
  },
  {
    name: "Китай",
    places: "174 предложений",
    className: "china",
    path: "china",
  },
  {
    name: "Мальдивы",
    places: "262 предложений",
    className: "maldives",
    path: "maldives",
  },
  {
    name: "Танзания",
    places: "216 предложений",
    className: "tanzania",
    path: "tanzania",
  },
  {
    name: "Кипр",
    places: "94 предложений",
    className: "cyprus",
    path: "cyprus",
  },
  {
    name: "Маврикий",
    places: "153 предложения",
    className: "mavriki",
    path: "mavriki",
  },

  // {
  //   name: "Иордания",
  //   places: "132 предложений",
  //   className: "jordan",
  //   path: "jordan",
  // },

  {
    name: "Гоа",
    places: "82 предложения",
    className: "goa",
    path: "goa",
  },

  {
    name: "Доминикана",
    places: "114 предложений",
    className: "dominicana",
    path: "dominicana",
  },
  {
    name: "Вьетнам",
    places: "162 предложений",
    className: "vietnam",
    path: "vietnam",
  },
  {
    name: "Мексика",
    places: "71 предложений",
    className: "mexico",
    path: "mexico",
  },
  {
    name: "Индия",
    places: "57 предложений",
    className: "india",
    path: "india",
  },

  {
    name: "Италия",
    places: "84 предложений",
    className: "italy",
    path: "italy",
  },
  {
    name: "Грузия",
    places: "164 предложений",
    className: "georgia",
    path: "georgia",
  },
  {
    name: "Испания",
    places: "261 предложений",
    className: "spain",
    path: "spain",
  },

  {
    name: "Греция",
    places: "132 предложений",
    className: "greece",
    path: "greece",
  },
  // Add more destinations as needed
];

const AllDestinations = () => {
  const groups = [];
  for (let i = 0; i < destinations.length; i += 4) {
    groups.push(destinations.slice(i, i + 4));
  }

  return (
    <section className="all-destinations-section-full">
      <div className="container-full">
        {" "}
        <h2>Все направления</h2>
        <div className="destinations-grid-wrapper">
          <Banner img={Banner1} text="" isVisa={false} />

          {groups.map((group, index) => (
            <div key={index} className="destinations-grid-full">
              {group.map((destination, idx) => (
                <Link to={`/countries/${destination.path}`}>
                  <div
                    key={idx}
                    className={`destination-card-full ${destination.className}`}
                  >
                    <div className="destination-info-full">
                      <h3>{destination.name}</h3>
                      <p className="dest">{destination.places}</p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AllDestinations;
