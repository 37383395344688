import React, { useState } from "react";
import Modal from "react-modal";
import "./ReviewsGallery.css";
import r1 from "../../assets/images/revies/r1.png";
import r2 from "../../assets/images/revies/r2.png";
import r3 from "../../assets/images/revies/r3.png";
import r4 from "../../assets/images/revies/r4.png";
import r5 from "../../assets/images/revies/r5.png";
import r6 from "../../assets/images/revies/r6.png";
import r7 from "../../assets/images/revies/r7.png";
import r8 from "../../assets/images/revies/r8.png";
import r9 from "../../assets/images/revies/r9.png";
import r10 from "../../assets/images/revies/r10.png";
import r11 from "../../assets/images/revies/r11.png";
import r12 from "../../assets/images/revies/r12.png";
import y1 from "../../assets/images/reviewss/y1.jpg";
import y2 from "../../assets/images/reviewss/y2.jpg";
import y3 from "../../assets/images/reviewss/y3.jpg";
import y4 from "../../assets/images/reviewss/y4.jpg";
import y5 from "../../assets/images/reviewss/y5.jpg";
import y6 from "../../assets/images/reviewss/y6.jpg";

import y7 from "../../assets/images/reviewss/y7.jpg";
import y8 from "../../assets/images/reviewss/y8.jpg";
import y9 from "../../assets/images/reviewss/y9.jpg";
import y10 from "../../assets/images/reviewss/y10.jpg";
import y11 from "../../assets/images/reviewss/y11.jpg";
import y12 from "../../assets/images/reviewss/y12.jpg";

import g1 from "../../assets/images/reviewss/g1.jpg";
import g2 from "../../assets/images/reviewss/g2.jpg";
import g3 from "../../assets/images/reviewss/g3.jpg";
import g4 from "../../assets/images/reviewss/g4.jpg";
import g5 from "../../assets/images/reviewss/g5.jpg";
import g6 from "../../assets/images/reviewss/g6.jpg";
import g7 from "../../assets/images/reviewss/g7.jpg";
import g8 from "../../assets/images/reviewss/g8.jpg";
import g9 from "../../assets/images/reviewss/g9.jpg";
import g10 from "../../assets/images/reviewss/g10.jpg";
import g11 from "../../assets/images/reviewss/g11.jpg";
import g12 from "../../assets/images/reviewss/g12.jpg";

import google from "../../assets/images/revies/google.png";
import holidayLogo from "../../assets/images/revies/holidayLogo.svg";
import yandex from "../../assets/images/revies/yandex.svg.png";
import vklogo from "../../assets/images/revies/vklogo.png";

const platforms = [
  {
    logo: google,
    name: "посмотреть отзывы в Google ",
    link: "https://www.google.pl/maps/place/%D0%9E%D0%9E%D0%9E+%22%D0%9C%D1%8D%D0%B9%D0%BA+%D0%A2%D1%80%D1%8D%D0%B2%D0%B5%D0%BB%22+-+%D1%81%D0%B5%D1%82%D1%8C+%D0%B4%D0%B8%D1%81%D0%BA%D0%B0%D1%83%D0%BD%D1%82%D0%B5%D1%80%D0%BE%D0%B2+%D0%B3%D0%BE%D1%80%D1%8F%D1%89%D0%B8%D1%85+%D1%82%D1%83%D1%80%D0%BE%D0%B2/@53.9197895,27.5826895,17z/data=!4m8!3m7!1s0x46dbcfa107adbfaf:0x5d2f3faa3ce98b7a!8m2!3d53.9197895!4d27.5826895!9m1!1b1!16s%2Fg%2F11f__d08g2?authuser=0&hl=ru&entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D",
    image: "/path/to/image1.jpg",
  },
  {
    logo: holidayLogo,
    name: "67 отзывов",
    link: "https://www.holiday.by/agencies/makemetravel/opinions",
    image: "/path/to/image2.jpg",
  },
  {
    logo: yandex,
    name: "110 отзывов",
    link: "https://yandex.ru/maps/org/make_travel/116708162985/reviews/?ll=27.582668%2C53.919861&z=14",
    image: "/path/to/image3.jpg",
  },
  {
    logo: vklogo,
    vk: true,
    name: "Более 1000 отзывов",
    link: "https://vk.com/topic-98811431_32387145?offset=1600",
    image: "/path/to/image4.jpg",
  },
];
const galleryY = [ y7, y8, y9, y10, y11, y12];
const galleryY1 = [y1, y2, y3, y4, y5, y6];

const galleryG = [g1, g2, g3, g4, g5, g6, g7, g8, g9,g10,g11,g12];
const galleryG1 = [g1, g2, g3, g4, g5, g6];

const galleryImages1 = [
  r1,
  r2,
  r3,
  r4,
  r5,
  r6,
  ,
  // ... add paths to other 10 images
];
const galleryImages = [
 
  r7,
  r8,
  r9,
  r10,
  r11,
  r12,
  // ... add paths to other 10 images
];
const ReviewsGallery = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage(null);
  };

  return (
    <div className="reviews-section">
      <div className="reviews-section-description">
        <div>
          <h2>Ваши отзывы — наш ориентир к совершенству</h2>
        </div>
        <p>
          Отзывы нашей компании — это наша точка роста. Мы тщательно анализируем
          ваши отзывы, как положительные, так и негативные, для улучшения
          качества работы. Ваше мнение для нас чрезвычайно важно, так как оно
          помогает нам лучше понимать ваши потребности и ожидания. Благодаря
          вашему обратному мнению, мы можем совершенствовать наши услуги,
          предлагая именно то, что необходимо вам. Мы стремимся к открытому
          диалогу с нашими клиентами, чтобы поддерживать высокий уровень доверия
          и взаимопонимания. В каждом отзыве мы видим возможность для роста и
          улучшения, и поэтому активно работаем над тем, чтобы каждый ваш опыт
          взаимодействия с нами был лучше предыдущего. Спасибо, что делитесь с
          нами своими впечатлениями — они помогают нам стать лучше каждый день.
        </p>
      </div>
      <div className="platforms">
        {platforms.map((platform, index) => (
          <a
            key={index}
            className="platform-item"
            href={platform.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={platform.logo}
              alt={platform.name}
              className={`${platform.vk && "vklogo"} platform-logo`}
            />
          </a>
        ))}
      </div>
      <div className="gallery-rev">
        {galleryY1.map((image, index) => (
          <div
            key={index}
            className="gallery-item-rev"
            onClick={() => openModal(image)}
          >
            <img
              src={image}
              alt={`Review ${index + 1}`}
              className="gallery-image-rev"
            />
          </div>
        ))}
      </div>
      <div className="gallery-rev">
        {galleryG1.map((image, index) => (
          <div
            key={index}
            className="gallery-item-rev"
            onClick={() => openModal(image)}
          >
            <img
              src={image}
              alt={`Review ${index + 1}`}
              className="gallery-image-rev"
            />
          </div>
        ))}
      </div>
      <div className="gallery-rev">
        {galleryImages1.map((image, index) => (
          <div
            key={index}
            className="gallery-item-rev"
            onClick={() => openModal(image)}
          >
            <img
              src={image}
              alt={`Review ${index + 1}`}
              className="gallery-image-rev"
            />
          </div>
        ))}
      </div>
      <div className="gallery-rev">
        {galleryY.map((image, index) => (
          <div
            key={index}
            className="gallery-item-rev"
            onClick={() => openModal(image)}
          >
            <img
              src={image}
              alt={`Review ${index + 1}`}
              className="gallery-image-rev"
            />
          </div>
        ))}
      </div>
      <div className="gallery-rev">
        {galleryG1.map((image, index) => (
          <div
            key={index}
            className="gallery-item-rev"
            onClick={() => openModal(image)}
          >
            <img
              src={image}
              alt={`Review ${index + 1}`}
              className="gallery-image-rev"
            />
          </div>
        ))}
      </div>
      <div className="gallery-rev">
        {galleryImages.map((image, index) => (
          <div
            key={index}
            className="gallery-item-rev"
            onClick={() => openModal(image)}
          >
            <img
              src={image}
              alt={`Review ${index + 1}`}
              className="gallery-image-rev"
            />
          </div>
        ))}
      </div>
      {/* 
      {selectedImage && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Image Modal"
          className="modal"
          overlayClassName="overlay"
        >
          <img
            src={selectedImage}
            alt="Selected Review"
            className="modal-image"
          />
        </Modal>
      )} */}
    </div>
  );
};

export default ReviewsGallery;
