// src/components/Loader/Loader.js
import React from 'react';
import './Loader.css';
import logo from '../../assets/images/white.png';

const Spinner = () => {
  return (
    
      <div className="random-spinner"></div>
    
  );
};

export default Spinner;
