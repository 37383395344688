import React from "react";
import { useParams } from "react-router-dom";
import countriesData from "../../data/countriesData";
import LazyLoad from "react-lazyload";
import "./CountryDetails.css";
import Spinner from "../Loader/Spinner";
import BackCallPost from "../BackCallPost/BackCallPost";
import TourvisorHotTours from "../TourWidget";

const CountryDetails = () => {
  const { countryId } = useParams();
  const country = countriesData[countryId];

  if (!country) {
    return <div>Страна не найдена</div>;
  }

  return (
    <section className="country-detail-section">
      <div className="container">
        <div className="cards-full-container">
          <div className="cards-images">
            <LazyLoad height={400} offset={100} placeholder={<Spinner />}>
              <img src={country.img1} alt="img" className="country-image" />
            </LazyLoad>
            {/* <LazyLoad
              height={400}
              offset={100}
              placeholder={<Spinner />}
            >
              <img src={country.img2} alt="img" className="country-image" />
            </LazyLoad> */}
          </div>
          <div className="text-container">
            <h2>{country.name}</h2>
            <p>{country.description}</p>
            <h3>Туристические факты</h3>
            <ul>
              {country.facts.map((fact, index) => (
                <li key={index}>{fact}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className="details-b">
          <BackCallPost buttonText="Подобрать тур" secondText="Заявка на подбор тура" />
        </div>
        <div className="tours-widget-search">
          <TourvisorHotTours />
        </div>{" "}
      </div>
    </section>
  );
};

export default CountryDetails;
