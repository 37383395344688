import React, { useState, useEffect } from "react";
import "./CallBack.scss";
import phoneIcon from "../../assets/images/air.png";
const Callback = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  console.log(isOpen);
  return (
    <div className="callback-widget">
      <div className="circle phone" onClick={toggleMenu}>
        {/* <div class="textInfo">Заказать звонок</div> */}
        <div className="iconBlock">
          <div className="iconPhone ">
            <i className="fa fa-phone" aria-hidden="true">
              <img src={phoneIcon} className={"iconPhone"} alt="phone" />
            </i>
          </div>
        </div>
      </div>
      {/* <a
        className={`circle menu ${isOpen ? "open5" : "openNo"}`}
        id="odno"
        href={
          'https://ok.ru/group/54232404656253'
        }
      ></a> */}
      {/* <a
        className={`circle menu ${isOpen ? "open4" : "openNo"}`}
        id="vk"
        href={
          'https://vk.com/maketravelby'
        }
      ></a>{" "} */}
      <a
        className={`circle menu ${isOpen ? "open" : "openNo"}`}
        id="viber"
        href={
          'viber://chat?number=+375296835050'
        }
      ></a>
      <a
        className={`circle menu ${isOpen ? "open1" : "openNo"}`}
        id="telegram"
        href={
          'https://t.me/Make_Travel1'
        }
      ></a>
      <a
        className={`circle menu ${isOpen ? "open2" : "openNo"}`}
        id="whatsup"
        href={
          "https://wa.me/375296835050"
        }
      ></a>
      {/* <a
        className={`circle menu ${isOpen ? "open3" : "openNo"}`}
        id="facebook"
        href="https://www.facebook.com/maketravelukraine/"
      ></a> */}
    </div>
  );
};

export default Callback;
