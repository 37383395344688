import React from 'react';
import './SupportSection.css';
import BackCallPost from '../BackCallPost/BackCallPost';

const SupportSection = () => {
  return (
    <section className="support-section">
      <div className="support-content">
        <h2>Остались вопросы?</h2>
        <BackCallPost 
          buttonText="Заказать звонок" 
          secondText="Заявка на обратный звонок"
        />
      </div>
    </section>
  );
};

export default SupportSection;
