import React from "react";
import "./OurMission.css"; // Make sure to create this CSS file
import img from "../../assets/images/people.png";
const OurMission = () => {
  return (
    <section className="our-mission">
      <div className="text-container-out">
        {/* стать самой крупной сетью в СНГ и давать лучший сервис! */}
        <h1>
          Мы стремимся стать самой крупной сетью в{" "}
          <span>СНГ</span> и предоставлять лучший <span>сервис</span>!
        </h1>
        {/* <p>
        На сегодняшний день в нашей компании работает более 80 человек, что является самым крупным штатом по количеству сотрудников среди турагентств Беларуси.
        </p> */}
      </div>
      <div className="image-container">
        <img src={img} alt="Team working together" />
      </div>
    </section>
  );
};

export default OurMission;
