import React, { useEffect } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "./Countries.css";

const destinations = [
  {
    name: "Египет",
    places: "347 предложений",
    className: "egypt",
    path: "egypt",
  },
  {
    name: "ОAЭ",
    places: "184 предложений",
    className: "oae",
    path: "oae",
  },
  {
    name: "Турция",
    places: "156 предложений",
    className: "turkey",
    path: "turkey",
  },
  {
    name: "Куба",
    places: "48 предложений",
    className: "cuba",
    path: "cuba",
  },
  
  {
    name: "Тайланд",
    places: "124 предложений",
    className: "thailand",
    path: "thailand",
  },
  {
    name: "Китай",
    places: "174 предложений",
    className: "china",
    path: "china",
  },
  {
    name: "Индонезия",
    places: "115 предложений",
    className: "indonesion",
    path: "indonesion",
  },
  {
    name: "Гоа",
    places: "82 предложения",
    className: "goa",
    path: "goa",
  },
  {
    name: "Шри-ланка",
    places: "123 предложения",
    className: "shrilanka",
    path: "shrilanka",
  },
   
  {
    name: "Мальдивы",
    places: "262 предложений",
    className: "maldives",
    path: "maldives",
  },
  {
    name: "Маврикий",
    places: "123 предложения",
    className: "mavriki",
    path: "mavriki",
  },
  {
    name: "Катар",
    places: "43 предложений",
    className: "qatar",
    path: "qatar",
  },
];

const Countries = () => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    const elements = document.querySelectorAll(".destination-card");
    elements.forEach((element) => observer.observe(element));

    return () => {
      elements.forEach((element) => observer.unobserve(element));
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <section className="next-home-section">
      <div className="container">
        <h2>Куда хотите отправиться?</h2>
        <p>
          Выберите самые интересные направления для{" "}
          <span className="red-span">вас</span>{" "}
        </p>
        <Slider {...settings}>
          {destinations.map((destination, index) => (
            <div key={index} className={`destination-card`}>
              <img 
                src={require(`../../assets/images/countries/${destination.className}.png`)} 
                alt={destination.name} 
                className="destination-image"
              />
              <div className="destination-info">
                <Link className="link" to={`/countries/${destination.path}`}>
                  <h3>{destination.name}</h3>
                  <p className="dest-text">{destination.places}</p>
                </Link>
              </div>
            </div>
          ))}
        </Slider>
        <Link to="/strany">
          <button className="explore-button">Посмотреть все</button>
        </Link>
      </div>
    </section>
  );
};

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="black"
        width="24px"
        height="24px"
      >
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17l-1.41-1.41L14.17 12 9.59 7.41 11 6l6 6-6 6z" />
      </svg>
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="black"
        width="24px"
        height="24px"
      >
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17l1.41-1.41L9.83 12l4.58-4.59L13 6l-6 6 6 6z" />
      </svg>
    </div>
  );
};

export default Countries;