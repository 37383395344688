// src/components/ErrorBoundary/ErrorBoundary.js
import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from './components/Loader/Loader';

// Обертка для использования хуков внутри классового компонента
function ErrorBoundaryWrapper({ children }) {
  const navigate = useNavigate();
  return <ErrorBoundary navigate={navigate}>{children}</ErrorBoundary>;
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Ошибка:", error, errorInfo);
  }

  componentDidUpdate() {
    if (this.state.hasError) {
      this.props.navigate('/');
      window.location.reload();
    }
  }

  render() {
    if (this.state.hasError) {
      return <Loader/>;
    }

    return this.props.children;
  }
}

export default ErrorBoundaryWrapper;
