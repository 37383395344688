import React from "react";
import Slider from "react-slick";
// import { FaCity } from 'react-icons/fa';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import CompanyLocationsMap from "../CompanyLocationsMap/CompanyLocationsMap";

const officeData = [
  { date: "23 ноября 2017", city: "Минск", count: 1 },
  { date: "19 апреля 2018", city: "Минск", count: 2 },
  { date: "16 июня 2018", city: "Брест", count: 1 },
  { date: "22 июня 2018", city: "Минск", count: 3 },
  { date: "12 сентября 2018", city: "Гомель", count: 1 },
  { date: "2 октября 2018", city: "Витебск", count: 1 },
  { date: "12 ноября 2018", city: "Гродно", count: 1 },
  { date: "16 декабря 2018", city: "Могилёв", count: 1 },
  { date: "18 мая 2019", city: "Гродно", count: 2 },
  // { date: "27 июля 2019", city: "Киев", count: 1 },
  { date: "28 ноября 2019", city: "Солигорск", count: 1 },
  { date: "25 января 2020", city: "Гомель", count: 2 },
  { date: "13 февраля 2020", city: "Гродно", count: 3 },
  { date: "10 марта 2020", city: "Молодечно", count: 1 },
  { date: "24 августа 2020", city: "Орша", count: 1 },
  { date: "17 сентября 2021", city: "Борисов", count: 1 },
];

const CompanyHistory = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true, // Добавляем centerMode для центрирования активного слайда
    responsive: [
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
       {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="slider-content-contaner">
      <div className="owner-main-history">
        <div className="owner-quote">
          <p>
            Официальный День Рождения компании Make Travel – 10 августа 2015
            года открылся наш первый офис. На сегодняшний день компания
            насчитывает 7 офисов в Беларуси
          </p>
          <p>
            На сегодняшний день в нашей компании работает более 80 человек, что
            является самым крупным штатом по количеству сотрудников среди
            турагентств Беларуси.
          </p>
          <p>
            Основополагающий принцип работы компании Maketravel – постоянное
            повышение экспертности в качестве отдыха. Как мы этого достигаем?
            Для решения данной задачи наши менеджеры несколько раз в год ездят
            по всему миру в рекламные туры и досконально на собственном опыте
            изучают курорты, отели, гостиницы, особенности проживания там,
            питание, сервис, инфраструктуру, участвуют в экскурсионных
            программах. Наши специалисты по туризму проверяют на себе то, что
            потом предлагают нашим клиентам.
          </p>
        </div>
        <div className="owner-info">
          <h1>История нашей компании</h1>
        </div>
      </div>
      <div className="slider-container">
        <Slider {...settings}>
          {officeData.map((item, index) => (
            <div key={index} className="slider-item">
              <div className="slider-index">{index + 1}</div>
              <div className="slider-content">
                <span className="slider-date">
                  {item.date} - {item.city}{" "}
                  {item.count > 1 ? `(${item.count}-й офис)` : ""}
                </span>
                {/* <FaCity className="slider-icon" /> */}
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <CompanyLocationsMap />
    </div>
  );
};

export default CompanyHistory;
