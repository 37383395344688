// src/components/HotTourWidget/HotTourWidget.js
import React, { useEffect } from "react";
import "./style.css";

if (typeof crypto.randomUUID !== 'function') {
  crypto.randomUUID = function() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  };
}

const HotTourWidget = ({ onWidgetLoaded }) => {
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "//tourvisor.ru/module/init.js";
  //   script.async = true;
  //   script.onload = onWidgetLoaded;
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, [onWidgetLoaded]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        position: "relative",
        alignItems: "center",
        top: "-20px",
      }}
    >
      <div style={{ width: "90%" }}>
        <h2 className="hotTitle">
          Хотите отправиться в горящий тур? 🔥
        </h2>

        <p className="hotTitle-p"> Скидки до -40% </p>
        <div className="tv-hot-tours tv-moduleid-9972957"></div>
      </div>
    </div>
  );
};

export default HotTourWidget;
