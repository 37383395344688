import React, { useState } from 'react';
import './Visa.css';

const advantages = [
    {
        id: 1,
        title: 'Доступные цены',
        description: 'Мы понимаем, что для многих путешественников важен бюджет, и наша задача — предложить доступные решения для получения визы. Наши услуги отличаются конкурентоспособными ценами, которые значительно ниже, чем у многих других компаний на рынке. Мы предоставляем полную прозрачность: никакие скрытые комиссии и дополнительные платежи вас не ждут. Оформляя визу у нас, вы получаете высококачественный сервис по доступной цене, что позволяет вам сэкономить до 50% по сравнению с другими агентствами. Мы также регулярно предлагаем скидки и специальные предложения, что делает наши услуги ещё более привлекательными для постоянных клиентов. Наша цель — сделать процесс получения визы доступным и экономически выгодным для каждого клиента, сохраняя высокий уровень обслуживания.',
        points: [
            'Низкие тарифы на все типы виз',
            'Без скрытых платежей',
            'Экономия до 50% по сравнению с другими сервисами',
        ],
        icon: '💸'
    },
    {
        id: 2,
        title: 'Простота оформления',

        description: 'Оформление визы с нами — это максимально упрощенный процесс, который избавляет вас от всех хлопот. Мы разработали удобную онлайн-платформу, на которой вам нужно лишь следовать простым шагам для подачи заявки. Забудьте о долгих очередях и бесконечном сборе документов — мы сделаем все за вас. Наши эксперты подготовят все необходимые документы, а вам останется лишь предоставить базовую информацию. Мы предоставляем пошаговые инструкции и консультации, чтобы избежать любых ошибок в процессе подачи. Наша задача — сделать процесс получения визы простым, удобным и быстрым для каждого клиента. Мы готовы взять на себя все сложности, чтобы вы могли сосредоточиться на подготовке к путешествию.', 
        points: [
            'Удобный онлайн-сервис',
            'Минимум документов от клиента',
            'Пошаговое руководство по подаче заявки',
        ],
        icon: '📝'
    },
    {
        id: 3,
        title: 'Быстрая обработка заявок',
        description: 'Когда речь идет о визах, время — это ключевой фактор, и мы это понимаем. Наша компания гарантирует быстрые сроки обработки заявок благодаря отлаженным процессам и профессионализму нашей команды. Сразу после подачи заявки мы начинаем работу, что позволяет значительно сократить время оформления визы. Вы получите визу без лишних задержек, что особенно важно для тех, кто планирует поездку в сжатые сроки. Мы также предлагаем услугу срочного оформления визы для клиентов, которым необходимо получить документы в максимально короткие сроки. С нашей помощью вы можете быть уверены, что ваша заявка будет обработана оперативно и эффективно, что позволит вам спокойно готовиться к поездке.',
        points: [
            'Моментальное начало работы после подачи заявки',
            'Минимальные сроки оформления',
            'Уведомление о готовности визы в кратчайшие сроки',
        ],
        icon: '⏱️'
    },
    {
        id: 4,
        title: 'Гарантия результата',
        description: 'Мы уверены в своих силах и предлагаем нашим клиентам гарантии на успех при оформлении визы. Каждый документ проходит тщательную проверку нашими специалистами, чтобы минимизировать риск отказа. В случае, если все же произойдет отказ, мы готовы вернуть вам деньги, что подтверждает нашу уверенность в качестве предоставляемых услуг. Мы поддерживаем вас на каждом этапе процесса — от первой консультации до получения визы. Наша цель — добиться одобрения вашей заявки, и мы делаем все возможное, чтобы это произошло. Наши клиенты могут быть уверены, что их заявка находится в надежных руках, и что мы приложим максимум усилий для достижения положительного результата.',
        points: [
            'Высокий процент одобрений',
            'Возврат денег в случае отказа',
            'Поддержка и сопровождение до получения визы',
        ],
        icon: '✅'
    },
     
];


const AdvantagesSection = () => {
    const [selectedAdvantage, setSelectedAdvantage] = useState(advantages[0]);

    const handleSelectAdvantage = (advantage) => {
        setSelectedAdvantage(advantage);
    };

    return (
        <div className="advantages-section">
            <div className="advantages-cards-viz">
                {advantages.map((advantage) => (
                    <div
                        key={advantage.id}
                        className={`card-viz ${selectedAdvantage.id === advantage.id ? 'active' : ''}`}
                        onClick={() => handleSelectAdvantage(advantage)}
                    >
                        <div className="card-viz-icon">{advantage.icon}</div>
                        <div className="card-viz-title">{advantage.title}</div>
                    </div>
                ))}
            </div>
            <div className="advantage-details">
                <h2>{selectedAdvantage.title}</h2>
                <p>{selectedAdvantage.description}</p>
                <ul>
                    {selectedAdvantage.points.map((point, index) => (
                        <li key={index}>{point}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default AdvantagesSection;