// src/components/TourvisorHotTours/TourvisorHotTours.js
import React, { useEffect } from "react";
import "./style.css";

if (typeof crypto.randomUUID !== "function") {
  crypto.randomUUID = function () {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
}

const TourvisorHotTours = ({ onWidgetLoaded }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//tourvisor.ru/module/init.js";
    script.async = true;
    script.onload = onWidgetLoaded;
    document.body.appendChild(script);

    return () => {
      // document.body.removeChild(script);
    };
  }, [onWidgetLoaded]);

  return (
    <div
      className="tour-widget"
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        position: "relative",
        top: "-20px",
      }}
    >
      <div style={{ width: "80%" }}>
        <div className="tv-search-form tv-moduleid-9966631"></div>
      </div>
    </div>
  );
};

export default TourvisorHotTours;
