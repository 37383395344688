import React, { useState } from 'react';
import './ContactsPage.css';
import PartnersSection from '../../components/PartnersSection/PartnersSection';
import contacts from '../../assets/images/company/8.jpg'

import vkLogo from '../../assets/images/sociallogo/vk.png'
import facebookLogo from '../../assets/images/sociallogo/facebook.png'
import odnoklassnikiLogo from '../../assets/images/sociallogo/odno.png'
import instagramLogo from '../../assets/images/sociallogo/inst.jpeg'
import telegramLogo from '../../assets/images/sociallogo/telegram1.png'
import viberLogo from '../../assets/images/sociallogo/viber1.png'
import whatsappLogo from '../../assets/images/sociallogo/whats.png'
import youtubeLogo from '../../assets/images/sociallogo/y1.png'
import OfficeTabs from './Offices';
import Banner from '../../components/Banner/Banner';
import banner from '../../assets/images/contacts/bannerC.jpg'
import Gallery from './GalleryContacts';
const socialPlatforms = [
    { name: 'ВКонтакте', logo: vkLogo, link: 'https://vk.com/maketravelby', vk: true },
    { name: 'Facebook', logo: facebookLogo, link: 'https://www.facebook.com/maketravelukraine/' },
    // { name: 'Одноклассники', logo: odnoklassnikiLogo, link: 'https://ok.ru/group/54232404656253' },
    { name: 'Instagram', logo: instagramLogo, link: 'https://www.instagram.com/maketravel.by/' },
    { name: 'Telegram', logo: telegramLogo, link:  'https://t.me/Make_Travel1' },
    { name: 'Viber', logo: viberLogo, link: 'viber://chat?number=+375296835050' },
    { name: 'WhatsApp', logo: whatsappLogo, link: "https://wa.me/375296835050"},
    // { name: 'YouTube', logo: youtubeLogo, link: 'https://www.youtube.com/channel/UCJAI31Hq0b040FCOzyvWETw' }
];

const offices = [
    {
        id: 1,
        city: 'Минск',
        offices: [
            {
                name: 'Офис №1 ТЦ "Coolman"',
                address: 'г. Минск ул. Кульман 3, офис 112 (1 этаж)',
                workingHours: 'Пн-Пт: 10.00-19.00 Сб-Вс: 10.00-19.00',
                contacts: [
                    { type: 'Velcom', phone: '+375-(29)-683-50-50' },
                    { type: 'MTC', phone: '+375-(33)-683-50-50' },
                    { type: 'Life', phone: '+375-(25)-683-50-50' },
                ],
            },
            {
                name: 'Офис №2',
                address: 'г. Минск, ул.Берута, 3б, 3 этаж, офис 306',
                workingHours: 'Пн-Пт: 10.00-19.00 Сб-Вс: выходной',
                contacts: [],
            },
        ],
    },
    {
        id: 2,
        city: 'Брест',
        offices: [
            {
                name: 'Офис №1 в Бресте',
                address: 'г. Брест ул.Советская 73, 3 этаж, офис 305',
                workingHours: 'Пн-Пт: 10.00-19.00 Сб-Вс: 10.00-19.00',
                contacts: [
                    { type: 'Velcom', phone: '+375-44-727-60-60' },
                    { type: 'Life', phone: '+375-25-727-60-60' },
                ],
            },
        ],
    },
    {
        id: 3,
        city: 'Гомель',
        offices: [
            {
                name: 'Офис №1 в Гомеле',
                address: 'г. Гомель ул. Жарковского, 26 , 3 этаж , офис 14',
                workingHours: 'Пн-Пт: 10.00-20.00 Сб-Вс: 10.00-19.00',
                contacts: [
                    { type: 'MTC', phone: '+375-29-7005-048' },
                ],
            },
        ],
    },
    {
        id: 4,
        city: 'Гродно',
        offices: [
            {
                name: 'Офис №1 в Гродно',
                address: 'г.Гродно, ул.Советская 18, 1 этаж, офис 120 (ТЦ "Неман")',
                workingHours: 'Пн-Пт: 10.00-19.00 Сб-Вс: 10.00-19.00',
                contacts: [
                    { type: 'Velcom', phone: '+375(44)557-90-90' },
                    { type: 'MTC', phone: '+375-(29)-7005-612' },
                ],
            },
        ],
    },
    {
        id: 5,
        city: 'Могилев',
        offices: [
            {
                name: 'Офис №1 в Могилеве',
                address: 'г.Могилев ул.Первомайская, 19 1этаж (отдельный вход с улицы)',
                workingHours: 'Пн-Пт: 10.00-19.00 Сб-Вс: 10.00-19.00',
                contacts: [
                    { type: 'Velcom', phone: '+375(29)370-95-95' },
                    { type: 'MTC', phone: '+375(33)370-95-95' },
                ],
            },
        ],
    },
];

const ContactsPage = () => {
    const [selectedTab, setSelectedTab] = useState(offices[0]);

    const handleTabClick = (office) => {
        setSelectedTab(office);
    };

    return (
        <div className="contacts-page">
           
            <div className="mergency-contact-descr-container">
                <img src={contacts} alt='contacts' />
                <div className="mergency-contact">
                    <p className="emergency-contact-descr">Современный сервис и работа напрямую более чем со 100
                        туроператорами, а также наш большой объём продаж позволяет нам предлагать цены на 20-30% ниже,
                        чем в других небольших агентствах.  </p>
                    <p className="emergency-contact-descr">Каждому нашему клиенту прикрепляется личный менеджер, который будет с Вами на связи на протяжении всего вашего отдыха, а также 24/7 с момента приобретиения тура.</p>
                </div>

            </div>
            

            <OfficeTabs />
            <div className="platforms-1">
                {socialPlatforms.map((platform, index) => (
                    <a
                        key={index}
                        className="platform-item-1"
                        href={platform.link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={platform.logo}
                            alt={platform.name}
                            className="platform-logo-1"
                        />
                    </a>
                ))}
            </div>
            <Banner isVisa={false} reverse={true} img={banner} text='Остались вопросы?' />
            <Gallery />
            <div className="emergency-contact">
                <h2>Телефон экстренной связи для наших клиентов (24/7)</h2>
                <a href="tel:+375296835050" className="phone-number">+375-29-683-50-50</a>
            </div>
            <PartnersSection />

        </div >
    );
};

export default ContactsPage;
