import React, { useState } from 'react';
import './HeroSection.css';
import video from '../../assets/video/travelVideo.mp4';
import logo from '../../assets/images/white.png';

const HeroSection = ({ onVideoLoaded }) => {
  const [videoLoaded, setVideoLoaded] = useState(false);

  const handleVideoLoaded = () => {
    setVideoLoaded(true);
    onVideoLoaded();
  };

  const handleScrollDown = () => {
    // Получаем высоту окна просмотра и скроллим на неё
    window.scrollTo({
      top: 370,
      behavior: 'smooth',
    });
  };

  return (
    <section className="hero">
      <video
        className="hero-video"
        src={video}
        autoPlay
        loop
        muted
        onLoadedData={handleVideoLoaded}
      />
      <div className="hero-overlay">
        <img src={logo} alt="Logo" />
      </div>
      <div className="scroll-down-arrow" onClick={handleScrollDown}>
        <span>&#x2193;</span> {/* Стрелка вниз */}
      </div>
    </section>
  );
};

export default HeroSection;
