import React, { useState, useEffect } from 'react'
import Modal from 'react-modal';
import './Gallery.css';
import p1 from '../../../assets/images/company/1.jpg'
import p2 from '../../../assets/images/company/2.jpg'
import p3 from '../../../assets/images/company/3.jpg'
import p4 from '../../../assets/images/company/4.png'
import p5 from '../../../assets/images/company/5.jpg'
import p6 from '../../../assets/images/company/6.jpg'
import p8 from '../../../assets/images/company/8.jpg'
import p7 from '../../../assets/images/company/7.jpg'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '90%',
        maxHeight: '90%',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
};


const photo = [
    {
        src: p1
    },
    {
        src: p2
    }, {
        src: p3
    }, {
        src: p4
    }, {
        src: p5
    }, {
        src: p6
    }, {
        src: p7
    },
    {
        src: p8
    },
];
const Gallery = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const openModal = (image) => {
        setSelectedImage(image);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedImage(null);
    };

    return (
        <div  className="gallery-container">
           <div className="gallery">
                {photo.slice(0, 8).map((image, index) => (
                    <div key={index} className={`gallery-item gallery-item-${index + 1}`} onClick={() => openModal(image)}>
                        <img src={image.src} />
                        <div className="overlay">

                        </div>
                    </div>
                ))}
                {selectedImage && (
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Image Modal"
                    >
                        <div className="modal-content">
                            <img src={selectedImage.src} alt={selectedImage.title} />
                            <div className="modal-text">
                                <h2>{selectedImage.title}</h2>
                                <p>{selectedImage.description}</p>
                                <p>{selectedImage.price}</p>
                            </div>
                        </div>
                    </Modal>
                )}
            </div>
        </div>
    );
};

export default Gallery;
