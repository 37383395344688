// src/pages/Main/Main.js
import React, { useState, useEffect } from "react";

 
import TransferInfo from "../../components/TransferInfo/TransferInfo.jsx";

const Transfer = () => {
 

  return (
    <div>
      <TransferInfo />
    </div>
  );
};

export default Transfer;
